<!-- @format -->

<template>
	<transition name="modal-fade">
		<div>
			<div class="modal-backdrop">
				<div
					class="modal"
					role="dialog"
					aria-labelledby="modalTitle"
					aria-describedby="modalDescription"
				>
					<header class="modal-header" id="modalTitle">
						<slot name="header">
							<span class="formMessage">{{ formMessage }}</span>
						</slot>
					</header>
					<section class="modal-body" id="modalDescription">
						<template slot="second"> Title </template>
						<slot name="body">
							<div class="margin-bottom-30 text-bold">
								Customer Id: &ensp; {{ customerId }} <br />
								Customer Title: &ensp; {{ customerTitle }} <br />
								Customer Nickname: &ensp; {{ customerNickname }} <br />
							</div>
							<ve-table
								:rows="rows"
								:columns="columns"
								:onCellClick="onCellClick"
								:onRowDoubleClick="onRowDoubleClick"
								:max-height="maxHeight"
							>
								<template slot="second">
									<div style="background-color: rgb(76, 92, 122)">
										<div class="table-header">
											Customer's Unpaid Reminded Invoices
										</div>
									</div>
								</template>
							</ve-table>
							<div
								style="
									background-color: rgb(76, 92, 122);
									padding: 25px;
									display: flex;
									justify-content: space-between;
								"
							>
								<b-button
									class="width-350"
									style="background-color: rgb(61, 196, 134); color: white"
									@click="onContinue"
									aria-label="Close modal"
								>
									Anyway continue to create the new order
								</b-button>
								<b-button
									type="is-danger"
									outlined
									class="width-350"
									@click="onCloseEditForm"
									aria-label="Close modal"
								>
									X Close
								</b-button>
							</div>
						</slot>
					</section>
					<footer class="modal-footer">
						<slot name="footer"> </slot>
					</footer>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { onMounted, ref, onUnmounted } from '@vue/composition-api'
import useCheckRisk from '../check-customer-risk/useCheckRisk'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
import useReturnOrderDispatchState from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchState.js'
import useCreditInvoiceState from '@/_srcv2/pages/sale-invoice/credit-invoice/scripts/useCreditInvoiceState.js'
import Store from '@/store'

export default {
	name: 'ModalInfoUnpaidRemindedInvoices',
	setup() {
		const formMessage = ref(`You can not create order due to customer's payment
							conditions!`)
		console.log('ModalInfoUnpaidRemindedInvoices initialized')
		const {
			customerIdCR: customerId,
			customerTitleCR: customerTitle,
			customerNicknameCR: customerNickname,
			columnsList: columns,
			rowsListUnpaidCR: rows,
			// ? ----------------------------------------
			showModalInfoUnpaidRemindedInvoices,
			refreshKeyModalInfoUnpaidRemindedInvoices,
			showCheckRiskModule,
		} = useCheckRisk()
		const onCloseEditForm = () => {
			showModalInfoUnpaidRemindedInvoices.value = false
		}
		onMounted(() =>
			console.log(
				'showModalInfoUnpaidRemindedInvoices',
				showModalInfoUnpaidRemindedInvoices.value,
			),
		)
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			console.log(params.row)
		}
		const maxHeight = ref('500px')
		// todo ----------------------------------------------------------------
		const {
			showSearchActiveCustomers,
			showGetDocumentHeadersDataComponent,
			refreshKeyGetDocumentHeadersDataComponent,
			createLevel,
		} = useCreateDocumentComponentState()
		// todo -----------------------------------------------------------------
		const {
			showCreateCreditInvoice,
			refreshKeyCreateCreditInvoice,
			showEnterInvoiceNumber,
			companyIdCI,
			customerIdCI,
		} = useCreditInvoiceState()
		// todo -----------------------------------------------------------------
		const {
			showCreateReturnOrderDispatch,
			refreshKeyCreateReturnOrderDispatch,
			showEnterDispatchNumber,
			companyIdRD,
			customerIdRD,
		} = useReturnOrderDispatchState()
		// todo -----------------------------------------------------------------
		const passTheTest = () => {
			console.log('------------->>>>>>>>>>>>>>>> passTest is fired')
			if (createLevel.value === 0) {
				companyIdRD.value = Store.getters.getUserCurrentCompany
				customerIdRD.value = customerId.value
				showModalInfoUnpaidRemindedInvoices.value = false
				showCreateReturnOrderDispatch.value = true
				showEnterDispatchNumber.value = true
				showSearchActiveCustomers.value = false
				showCheckRiskModule.value = false
				refreshKeyCreateReturnOrderDispatch.value += 1
			} else if (createLevel.value === 4) {
				companyIdCI.value = Store.getters.getUserCurrentCompany
				customerIdCI.value = customerId.value
				showModalInfoUnpaidRemindedInvoices.value = false
				showCreateCreditInvoice.value = true
				showEnterInvoiceNumber.value = true
				showSearchActiveCustomers.value = false
				showCheckRiskModule.value = false
				refreshKeyCreateCreditInvoice.value += 1
			} else {
				showModalInfoUnpaidRemindedInvoices.value = false
				showGetDocumentHeadersDataComponent.value = true
				refreshKeyGetDocumentHeadersDataComponent.value += 1
				showSearchActiveCustomers.value = false
				showCheckRiskModule.value = false
			}
		}

		const failTheTest = () => {
			showModalInfoUnpaidRemindedInvoices.value = false
			showCheckRiskModule.value = false
		}
		const unlockEditPrice = () => {
			let pwd = prompt('Type password to create a list')
			if (pwd === '1994') {
				passTheTest()
			} else
				alert(`Password does not match
	You don't have permission
	to create price list
	contact with administrator`)
			failTheTest()
		}
		// todo --------------------------------------------------------------------
		const onContinue = () => {
			if (Store.getters.getUserName.toUpperCase() === 'DEV') {
				console.log('DEV')
				passTheTest()
			} else if (Store.getters.getUserName.toUpperCase() === 'ISMAIL') {
				unlockEditPrice()
			} else {
				alert(
					'This Customer has unpaid reminder or risky \n You can not create order',
				)
				failTheTest()
			}
		}
		onUnmounted(() => {
			console.log('ModalInfoUnpaidRemindedInvoices unmounted')
		})
		return {
			formMessage,
			onCloseEditForm,
			customerId,
			customerTitle,
			customerNickname,
			rows,
			columns,
			showModalInfoUnpaidRemindedInvoices,
			refreshKeyModalInfoUnpaidRemindedInvoices,
			onCellClick,
			onRowDoubleClick,
			maxHeight,
			onContinue,
		}
	},
}
</script>

<style scoped>
.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100000000001;
}

.modal {
	background: #ffffff;
	box-shadow: 2px 2px 20px 1px;
	overflow-x: auto;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	max-width: 90%;
	max-height: 90%;
	height: 1500px;
	margin: auto;
	padding: 20px;
	opacity: 1;
}

.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
}

.modal-header {
	position: relative;
	color: #4aae9b;
	justify-content: space-between;
}

.modal-footer {
	flex-direction: column;
	justify-content: flex-end;
}

.modal-body {
	position: relative;
	padding: 20px 10px;
}

.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}
.formMessage {
	color: red;
	font-size: xx-large;
	font-weight: 500;
	margin-top: 20px;
}
</style>
