<!-- @format -->

<template>
	<div>
		<div>
			<div class="margin-top-30">
				<div style="background-color: rgb(76, 92, 122)">
					<div class="table-title">{{ formTitle1 }}</div>
				</div>
				<div :class="isFormDisabled ? 'disable-div' : 'enable-div'">
					<ve-table
						:rows="creditInvoiceGoodsTransactionsLines"
						:columns="columns1"
						:onCellClick="onCellClick1"
						:onRowDoubleClick="onRowDoubleClick1"
						:searchedOptions="false"
						:perPage="perPageProp"
						maxHeight="1500px"
					>
					</ve-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import useCreditInvoiceState from '@/_srcv2/pages/sale-invoice/credit-invoice/scripts/useCreditInvoiceState.js'
import { ref, computed, onMounted, reactive } from '@vue/composition-api'

export default {
	name: 'CreditInvoiceLines',
	setup() {
		// todo -----------------------------------------------------------------
		const {
			columns1,
			creditInvoiceGoodsTransactionsLines,
			invoiceGoodsTransactionsLines,
			invoice_type,
			invoice_language,
			invoice_exchange_rate,
			invoice_exchange_unit,
			isFormDisabled,
		} = useCreditInvoiceState()
		// todo -----------------------------------------------------------------
		const formTitle1 = computed(() => {
			return `
  	       The items to be added to credit invoice - ${invoice_type.value} - ${invoice_language.value} - (1 kr. = ${invoice_exchange_rate.value} ${invoice_exchange_unit.value})
  	       `
		})
		const perPageProp = ref(20)
		onMounted(() => {
			creditInvoiceGoodsTransactionsLines.value = []
		})
		// todo  -----------------------------------------------------------------------

		const setTempLine = (row) => {
			const goodTransactionsLine = reactive({
				amount_credit: 0,
				dispatch_amount: 0,
				order_amount: 0,
				goods_transaction_id: '',
				invoice_price: 0,
				invoice_price_exchange: 0,
				invoice_price_exchange_cleave: '',
				line_info: '',
				stock_id: '',
				transaction_type: '',
				unit_price: 0,
				vat_percent: 0,
				vat_credit: 0,
				vat_credit_exchange: 0,
				invoice_exchange_unit: '',
				line_price_total_credit_exchange: 0,
			})
			return new Promise((resolve, reject) => {
				if (row !== undefined && row !== null) {
					goodTransactionsLine.amount_credit = -1 * row.amount_credit
					goodTransactionsLine.dispatch_amount = -1 * row.amount_credit
					goodTransactionsLine.order_amount = -1 * row.amount_credit
					goodTransactionsLine.goods_transaction_id = row.goods_transaction_id
					goodTransactionsLine.invoice_price = row.invoice_price
					goodTransactionsLine.invoice_price_exchange =
						row.invoice_price_exchange
					goodTransactionsLine.invoice_price_exchange_cleave =
						row.invoice_price_exchange_cleave
					goodTransactionsLine.line_info = row.line_info
					goodTransactionsLine.stock_id = row.stock_id
					goodTransactionsLine.transaction_type = row.transaction_type
					goodTransactionsLine.unit_price = row.unit_price
					goodTransactionsLine.vat_percent = row.vat_percent
					goodTransactionsLine.vat_credit = -1 * row.vat_credit
					goodTransactionsLine.vat_credit_exchange =
						-1 * row.vat_credit_exchange
					goodTransactionsLine.invoice_exchange_unit = row.invoice_exchange_unit
					goodTransactionsLine.line_price_total_credit_exchange =
						-1 * row.line_price_total_credit_exchange
					resolve(goodTransactionsLine)
				} else {
					const reason = new Error('Row is undefined or null')
					reject(reason)
				}
			})
		}
		// ? -----------------------------------------------------------------------
		const arrayRemove = (arr, value) => {
			console.log('value', value)
			return arr.filter(function (item) {
				return item.goods_transaction_id !== value
			})
		}
		// todo ----------------------------------------------------------------------
		const onCellClick1 = (params) => {
			if (params.column.field === 'delete') {
				creditInvoiceGoodsTransactionsLines.value = arrayRemove(
					creditInvoiceGoodsTransactionsLines.value,
					params.row.goods_transaction_id,
				)
				setTempLine(params.row).then((line) =>
					invoiceGoodsTransactionsLines.value.push(line),
				)
			}
		}
		const onRowDoubleClick1 = (params) => {
			console.log(params.row)
		}
		// todo ---------------------------------------------------------------------
		return {
			creditInvoiceGoodsTransactionsLines,
			columns1,
			formTitle1,
			perPageProp,
			onCellClick1,
			onRowDoubleClick1,
			isFormDisabled,
		}
	},
}
</script>

<style lang="scss" scoped></style>
