<!-- @format -->

<template>
	<div></div>
</template>

<script>
import useCheckRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/useCheckRisk.js'
import { onMounted } from '@vue/composition-api'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
import useReturnOrderDispatchState from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchState.js'
import useCreditInvoiceState from '@/_srcv2/pages/sale-invoice/credit-invoice/scripts/useCreditInvoiceState.js'
import Store from '@/store'

export default {
	name: 'DecideComponent',
	setup() {
		console.log('DecideComponent initialized')
		const {
			showSearchActiveCustomers,
			showGetDocumentHeadersDataComponent,
			refreshKeyGetDocumentHeadersDataComponent,
			createLevel,
		} = useCreateDocumentComponentState()
		// todo createReturnDispatchOrder --------------------
		const {
			showCreateReturnOrderDispatch,
			refreshKeyCreateReturnOrderDispatch,
			showEnterDispatchNumber,
			refreshKeyEnterDispatchNumber,
			companyIdRD,
			customerIdRD,
			// customerRD,
		} = useReturnOrderDispatchState()
		// todo createCreditInvoiceDispatchOrder --------------------
		const {
			showCreateCreditInvoice,
			refreshKeyCreateCreditInvoice,
			showEnterInvoiceNumber,
			refreshKeyEnterInvoiceNumber,
			companyIdCI,
			customerIdCI,
			// customerCI,
		} = useCreditInvoiceState()

		const {
			customerIdCR: customerId,
			// ? ----------------------------------------
			customerIsRiskyCR,
			unpaidRemindedInvoiceAmount,
			showModalInfoUnpaidRemindedInvoices,
			refreshKeyModalInfoUnpaidRemindedInvoices,
			showCheckRiskModule,
		} = useCheckRisk()

		const showModal = () => {
			console.log('customer is risky')
			console.log('showModal is fired')
			showModalInfoUnpaidRemindedInvoices.value = true
			refreshKeyModalInfoUnpaidRemindedInvoices.value += 1
		}

		const passTheTest = () => {
			console.log('DecideComponent passTheTest is fired')
			// console.log('-*-*-*-*>>> customerRD', customerRD.value)
			if (createLevel.value === 0) {
				console.log('createLevel is ', createLevel.value)
				companyIdRD.value = Store.getters.getUserCurrentCompany
				customerIdRD.value = customerId.value
				showModalInfoUnpaidRemindedInvoices.value = false
				showCreateReturnOrderDispatch.value = true
				showEnterDispatchNumber.value = true
				refreshKeyEnterDispatchNumber.value += 1
				showSearchActiveCustomers.value = false
				showCheckRiskModule.value = false
				refreshKeyCreateReturnOrderDispatch.value += 1
			} else if (createLevel.value === 4) {
				console.log('createLevel is ', createLevel.value)
				companyIdCI.value = Store.getters.getUserCurrentCompany
				customerIdCI.value = customerId.value
				showModalInfoUnpaidRemindedInvoices.value = false
				showCreateCreditInvoice.value = true
				showEnterInvoiceNumber.value = true
				refreshKeyEnterInvoiceNumber.value += 1
				showSearchActiveCustomers.value = false
				showCheckRiskModule.value = false
				refreshKeyCreateCreditInvoice.value += 1
			} else {
				showSearchActiveCustomers.value = false
				showGetDocumentHeadersDataComponent.value = true
				refreshKeyGetDocumentHeadersDataComponent.value += 1
			}
		}
		onMounted(() => {
			console.log(
				'DecideComponent onMounted unpaidRemindedInvoiceAmount.value',
				unpaidRemindedInvoiceAmount.value,
			)
			if (customerIsRiskyCR.value === true) {
				showModal()
			} else if (unpaidRemindedInvoiceAmount.value > 0) {
				showModal()
			} else {
				console.log('customer is not risky')
				passTheTest()
			}
		})

		return {}
	},
}
</script>

<style lang="scss" scoped></style>
