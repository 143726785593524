/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

// todo ------------------------------------------------------------------------------------------------
async function getExchangeRates(excUnit) {
	console.log('excUnit', excUnit)
	const response = await fetch(
		`https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_xKXNX5PhvGE9yikxSSWikKkQLxZofwdn56x2HeyW&currencies=EUR%2CUSD%2CNOK%2CDKK%2CTRY%2CPLN&base_currency=SEK`,
	)
	const result = await response.json()
	const excRate = await result.data[excUnit]
	console.log('excRate', excRate)
	return excRate
}
// ? ---------------------------------------------------------------------------------------------------
export default function useExchangeRate() {
	return {
		getExchangeRates,
	}
}
// todo -------------------------------------------------------------------------------------------------
