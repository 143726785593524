<!-- @format -->

<template>
	<div>
		<div class="margin-top-30">
			<div style="background-color: rgb(76, 92, 122)">
				<div class="table-title">{{ formTitle2 }}</div>
			</div>
			<div :class="isFormDisabled ? 'disable-div' : 'enable-div'">
				<ve-table
					:rows="invoiceGoodsTransactionsLines"
					:columns="columns2"
					:onCellClick="onCellClick2"
					:onRowDoubleClick="onRowDoubleClick2"
					:searchedOptions="false"
					:perPage="perPageProp"
					maxHeight="1500px"
				>
				</ve-table>
			</div>
		</div>
	</div>
</template>

<script>
import useCreditInvoiceState from '@/_srcv2/pages/sale-invoice/credit-invoice/scripts/useCreditInvoiceState.js'
import { ref, computed, onMounted, reactive } from '@vue/composition-api'
import useFormatNumber from '@/_srcv2/composables/format-numbers/useFormatNumber.js'
// import useGetUuid from '@/_srcv2/views/_shared/_composables/create-uuid/useGetUuid.js'

export default {
	name: 'InvoiceToSelectItemFrom',
	setup() {
		// const { getUUID } = useGetUuid()
		const { intToFloatCleave } = useFormatNumber()
		// todo -----------------------------------------------------------------
		const {
			invoiceGoodsTransactionsLines,
			creditInvoiceGoodsTransactionsLines,
			columns2,
			invoiceNumberCI,
			invoice_type,
			invoice_language,
			invoice_exchange_rate,
			invoice_exchange_unit,
			isFormDisabled,
		} = useCreditInvoiceState()
		const rows = computed(() => invoiceGoodsTransactionsLines.value)
		const logArray = () =>
			console.log('@click InvoiceToSelectItemFrom rows.value', rows.value)
		// todo -----------------------------------------------------------------
		const formTitle2 = computed(() => {
			return `
	       ${invoiceNumberCI.value} - ${invoice_type.value} - ${invoice_language.value} - (1 kr. = ${invoice_exchange_rate.value} ${invoice_exchange_unit.value})
	       `
		})
		const perPageProp = ref(20)
		onMounted(() => {
			console.log(
				'onMounted InvoiceToSelectItemFrom invoiceGoodsTransactionsLines.value',
				invoiceGoodsTransactionsLines.value,
			)
			console.log('onMounted InvoiceToSelectItemFrom rows.value', rows.value)
		})
		// ? -----------------------------------------------------------------------

		const setTempLine = (row) => {
			const goodTransactionsLine = reactive({
				amount_credit: 0,
				dispatch_amount: 0,
				order_amount: 0,
				goods_transaction_id: '',
				invoice_price: 0,
				invoice_price_exchange: 0,
				invoice_price_exchange_cleave: '',
				line_info: '',
				stock_id: '',
				transaction_type: '',
				unit_price: 0,
				vat_percent: 0,
				vat_credit: 0,
				vat_credit_exchange: 0,
				invoice_exchange_unit: '',
				line_price_total_credit: 0,
				line_price_total_credit_exchange: 0,
			})
			return new Promise((resolve, reject) => {
				if (row !== undefined && row !== null) {
					console.log('setTempLine row', row)
					goodTransactionsLine.amount_credit = -1 * row.amount_credit
					goodTransactionsLine.dispatch_amount = -1 * row.amount_credit
					goodTransactionsLine.order_amount = -1 * row.amount_credit
					goodTransactionsLine.goods_transaction_id = row.goods_transaction_id
					goodTransactionsLine.invoice_price = row.invoice_price
					goodTransactionsLine.invoice_price_exchange =
						row.invoice_price_exchange
					goodTransactionsLine.invoice_price_exchange_cleave = intToFloatCleave(
						row.invoice_price_exchange,
					)
					goodTransactionsLine.line_info = `${invoiceNumberCI.value} - ${row.line_info}`
					goodTransactionsLine.stock_id = row.stock_id
					goodTransactionsLine.transaction_type = 'customer order'
					goodTransactionsLine.unit_price = row.unit_price
					goodTransactionsLine.vat_percent = row.vat_percent
					goodTransactionsLine.vat_credit = -1 * row.vat_credit
					goodTransactionsLine.vat_credit_exchange =
						-1 * row.vat_credit_exchange
					goodTransactionsLine.invoice_exchange_unit = row.invoice_exchange_unit
					goodTransactionsLine.line_price_total_credit =
						row.line_price_total_credit * -1
					goodTransactionsLine.line_price_total_credit_exchange =
						-1 * row.line_price_total_credit_exchange
					resolve(goodTransactionsLine)
				} else {
					const reason = new Error('Row is undefined or null')
					reject(reason)
				}
			})
		}
		// ? -----------------------------------------------------------------------
		const arrayRemove = (arr, value) => {
			console.log('value', value)
			return arr.filter(function (item) {
				return item.goods_transaction_id !== value
			})
		}
		// todo ----------------------------------------------------------------------
		const onCellClick2 = (params) => {
			if (params.column.field === 'select') {
				setTempLine(params.row).then((line) => {
					creditInvoiceGoodsTransactionsLines.value.push(line)
					console.log('line', line)
				})
				console.log('params.row', params.row.goods_transaction_id)
				invoiceGoodsTransactionsLines.value = arrayRemove(
					invoiceGoodsTransactionsLines.value,
					params.row.goods_transaction_id,
				)
			}
		}
		const onRowDoubleClick2 = (params) => {
			console.log(params.row)
		}
		// todo -----------------------------------------------------------------
		return {
			invoiceGoodsTransactionsLines,
			columns2,
			formTitle2,
			perPageProp,
			onCellClick2,
			onRowDoubleClick2,
			isFormDisabled,
			// goodTransactionsLine,
			logArray,
		}
	},
}
</script>

<style lang="scss" scoped></style>
