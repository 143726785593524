<template>
	<div :class="isFormDisabled ? 'disable-div' : 'enable-div'">
		<GetSelectedDispatchesList v-if="showGetSelectedDispatchesList" />
		<AddOtherDispatchesToCurrentInvoice v-if="!showGetSelectedDispatchesList" />
	</div>
</template>

<script>
import GetSelectedDispatchesList from './GetSelectedDispatchesList.vue'
import AddOtherDispatchesToCurrentInvoice from './AddOtherDispatchesToCurrentInvoice.vue'
import useSalesInvoiceState from '@/_srcv2/pages/sale-invoice/_shared/useSalesInvoiceState.js'
import useDraftDocumentLines from '@/_srcv2/pages/admin/create-document-add-lines/draft-document-lines/useDraftDocumentLines.js'

export default {
	name: 'AddOtherDispatchesFrame',
	components: {
		GetSelectedDispatchesList,
		AddOtherDispatchesToCurrentInvoice,
	},
	setup() {
		const { isFormDisabled } = useDraftDocumentLines()
		const { showGetSelectedDispatchesList } = useSalesInvoiceState()
		return {
			showGetSelectedDispatchesList,
			isFormDisabled,
		}
	},
}
</script>

<style lang="scss" scoped></style>
