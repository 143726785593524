<!-- @format -->

<template>
	<div>
		<div class="margin-top-30">
			<div style="background-color: rgb(76, 92, 122)">
				<div class="table-title">{{ formTitle2 }}</div>
			</div>
			<div :class="isFormDisabled ? 'disable-div' : 'enable-div'">
				<ve-table
					:rows="dispatchGoodsTransactionsLines"
					:columns="columns2"
					:onCellClick="onCellClick2"
					:onRowDoubleClick="onRowDoubleClick2"
					:searchedOptions="false"
					:perPage="perPageProp"
					maxHeight="1500px"
				>
				</ve-table>
			</div>
		</div>
	</div>
</template>

<script>
import useReturnOrderDispatchState from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchState.js'
import useReturnDispatchModalSchemaOptions from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchModalSchemaOptions.js'
import { ref, computed, onMounted } from '@vue/composition-api'
import useFormatNumber from '@/_srcv2/composables/format-numbers/useFormatNumber.js'
import useGetUuid from '@/_srcv2/views/_shared/_composables/create-uuid/useGetUuid.js'

export default {
	name: 'DispatchToSelectItemFrom',
	setup() {
		const { getUUID } = useGetUuid()
		const { intToFloatCleave } = useFormatNumber()
		// todo -----------------------------------------------------------------
		const {
			// rows1,
			dispatchGoodsTransactionsLines,
			columns2,
			dispatchNumberRD,
			dispatch_type,
			dispatch_language,
			dispatch_exchange_rate,
			dispatch_exchange_unit,
			isFormDisabled,
			isModalVisible,
			keyAddDocumentLinesModal,
		} = useReturnOrderDispatchState()
		// todo ------------------------------------------------------------------
		const { modelOrderLines, lineState } = useReturnDispatchModalSchemaOptions()
		// todo -----------------------------------------------------------------
		const formTitle2 = computed(() => {
			return `
	       ${dispatchNumberRD.value} - ${dispatch_type.value} - ${dispatch_language.value} - (1 kr. = ${dispatch_exchange_rate.value} ${dispatch_exchange_unit.value})
	       `
		})
		const perPageProp = ref(20)
		// const rows2 = ref([])
		onMounted(() => {
			console.log(
				'onMounted DispatchToSelectItemFrom dispatchGoodsTransactionsLines',
				dispatchGoodsTransactionsLines.value,
			)
		})
		// ? -----------------------------------------------------------------------
		const setTempLine = (row) => {
			return new Promise((resolve, reject) => {
				if (row !== undefined && row !== null) {
					modelOrderLines.amount_credit = ''
					modelOrderLines.goods_transaction_id = getUUID()
					modelOrderLines.invoice_price = row.invoice_price
					modelOrderLines.invoice_price_exchange = row.invoice_price_exchange
					modelOrderLines.invoice_price_exchange_cleave = intToFloatCleave(
						row.invoice_price_exchange,
					)
					modelOrderLines.line_info = `${dispatchNumberRD.value} - ${row.line_info}`
					modelOrderLines.stock_id = row.stock_id
					modelOrderLines.transaction_type = 'customer order'
					modelOrderLines.unit_price = row.unit_price
					modelOrderLines.vat_percent = row.vat_percent
					lineState.amount_creditRD = row.amount_credit
					lineState.vat_creditRD = row.vat_credit
					lineState.vat_credit_exchangeRD = row.vat_credit_exchange
					lineState.line_price_total_creditRD = row.line_price_total_credit
					lineState.line_price_total_credit_exchangeRD =
						row.line_price_total_credit_exchange
					resolve(modelOrderLines)
				} else {
					const reason = new Error('Row is undefined or null')
					reject(reason)
				}
			})
		}
		// ? -----------------------------------------------------------------------
		const onCellClick2 = (params) => {
			if (params.column.field === 'select') {
				console.log('params.row', params.row)
				setTempLine(params.row).then((line) => {
					console.log('line', line)
					// console.log('rows1.value', rows1.value)
					// console.log('typeof rows1.value', typeof rows1.value)
					isModalVisible.value = true
					keyAddDocumentLinesModal.value += 1
				})
			}
		}
		const onRowDoubleClick2 = (params) => {
			console.log(params.row)
		}
		// todo -----------------------------------------------------------------
		return {
			// rows2,
			dispatchGoodsTransactionsLines,
			columns2,
			formTitle2,
			perPageProp,
			onCellClick2,
			onRowDoubleClick2,
			isFormDisabled,
			modelOrderLines,
			// rows1,
		}
	},
}
</script>

<style lang="scss" scoped></style>
