/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'

Vue.use(VueCompositionApi)

const componentsState = reactive({
	isCreateDocumentVisible: false,
	refreshKeyCreateDocument: 0,
	// todo Show Components -------------
	showSearchActiveCustomers: true,
	// ? ------------------------------------------
	showGetDocumentHeadersDataComponent: false,
	refreshKeyGetDocumentHeadersDataComponent: 0,
	// ? ------------------------------------------
	showDocumentHeadersGroup: false,
	refreshKeyDocumentHeadersGroup: 0,
	// ? ------------------------------------------
	showAddGoodsTransactions: false,
	refreshKeyAddGoodsTransactions: 0,
	// todo CreateOrderDispatch(Invoice) -------------
	createLevel: 0,
})

export default function useCreateDocumentComponentState() {
	return {
		...toRefs(componentsState),
	}
}
