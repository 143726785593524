/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const orderCountriesState = reactive({
	inlandCountries: ['Sverige'],
	euCountries: [
		'Belgien',
		'Bulgarien',
		'Cypern',
		'Danmark',
		'Estland',
		'Finland',
		'Frankrike',
		'Grekland',
		'Irland',
		'Italien',
		'Kroatien',
		'Lettland',
		'Litauen',
		'Luxemburg',
		'Malta',
		'Nederländerna',
		'Polen',
		'Portugal',
		'Rumänien',
		'Slovakien',
		'Slovenien',
		'Spanien',
		'Tjeckien',
		'Tyskland',
		'Ungern',
		'Österrike',
	],
	otherCountries: [
		'Albanien',
		'Bosnien och Hercegovina',
		'Georgien',
		'Moldavien',
		'Montenegro',
		'Nordmakedonien',
		'Serbien',
		'Turkiet',
		'Ukraina',
	],
})
const getDropdownCountriesList = () => {
	const outSwedenList = [
		...orderCountriesState.euCountries,
		...orderCountriesState.otherCountries,
	].sort()
	return [...orderCountriesState.inlandCountries, ...outSwedenList]
}
getDropdownCountriesList()
// todo -------------------------------------------------------------------------
let requestOptions = {
	method: 'GET',
	redirect: 'follow',
}
async function getCountriesList() {
	const response = await fetch(
		`https://countryapi.io/api/all?apikey=uksDA3l0Mk2SW3AMUNP203UWWa6nuVmkrNJyWPUl`,
		requestOptions,
	)
	const result = await response.json()
	return result
}

// ? ----------------------------------------------------------------------------

export default function useCountriesApi() {
	return {
		getDropdownCountriesList,
		getCountriesList,
		...toRefs(orderCountriesState),
	}
}
