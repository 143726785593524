<!-- @format -->

<template>
	<div>
		<!-- only for create return order dispatch -->
		<div id="CreateCreditInvoiceDispatchOrderFrame">
			<div v-if="showEnterInvoiceNumber">
				<EnterInvoiceNumber :key="refreshKeyEnterInvoiceNumber" />
			</div>
			<div v-if="showGetCustomerInvoiceByNumber">
				<GetCustomerInvoiceByNumber
					:key="refreshKeyGetCustomerInvoiceByNumber"
				/>
			</div>
			<div v-if="showTheInvoiceToSelectItemToCredit">
				<TheInvoiceToSelectItemToBeCredited
					:key="refreshKeyTheInvoiceToSelectItemToCredit"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import EnterInvoiceNumber from '@/_srcv2/pages/sale-invoice/credit-invoice/components/EnterInvoiceNumber.vue'
import GetCustomerInvoiceByNumber from '@/_srcv2/pages/sale-invoice/credit-invoice/components/GetCustomerInvoiceByNumber.vue'
import TheInvoiceToSelectItemToBeCredited from '@/_srcv2/pages/sale-invoice/credit-invoice/components/the-invoice-to-select-item-to-be-credited/TheInvoiceToSelectItemToBeCredited.vue'
import useCreditInvoiceState from '@/_srcv2/pages/sale-invoice/credit-invoice/scripts/useCreditInvoiceState.js'

export default {
	name: 'CreateCreditInvoiceDispatchOrderFrame',
	components: {
		GetCustomerInvoiceByNumber,
		EnterInvoiceNumber,
		TheInvoiceToSelectItemToBeCredited,
	},
	setup() {
		const {
			showCreateCreditInvoice,
			showEnterInvoiceNumber,
			refreshKeyEnterInvoiceNumber,
			showGetCustomerInvoiceByNumber,
			refreshKeyGetCustomerInvoiceByNumber,
			showTheInvoiceToSelectItemToCredit,
			refreshKeyTheInvoiceToSelectItemToCredit,
		} = useCreditInvoiceState()
		// todo ---------------------------------------------------------------------------
		onMounted(() => {
			showEnterInvoiceNumber.value = true
			refreshKeyEnterInvoiceNumber.value += 1
			showGetCustomerInvoiceByNumber.value = false
			showTheInvoiceToSelectItemToCredit.value = false
		})
		return {
			showCreateCreditInvoice,
			showEnterInvoiceNumber,
			refreshKeyEnterInvoiceNumber,
			showGetCustomerInvoiceByNumber,
			refreshKeyGetCustomerInvoiceByNumber,
			showTheInvoiceToSelectItemToCredit,
			refreshKeyTheInvoiceToSelectItemToCredit,
		}
	},
}
</script>

<style lang="scss" scoped></style>
