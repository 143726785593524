/** @format */

import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import { reactive, toRefs } from '@vue/composition-api'
// import Store from '@/store'

const creditInvoiceState = reactive({
	refreshKeyCreateCreditInvoice: 0, //*
	showCreateCreditInvoice: false, //*
	showEnterInvoiceNumber: false, //*
	refreshKeyEnterInvoiceNumber: 0, //*
	refreshKeyGetCustomerInvoiceByNumber: 0, //*
	showGetCustomerInvoiceByNumber: false, //*
	refreshKeyTheInvoiceToSelectItemToCredit: 0, //*
	showTheInvoiceToSelectItemToCredit: false, //*
	isFormDisabled: false,
	// todo ---------------------------------
	companyIdCI: '', //*
	customerIdCI: '', //*
	invoiceNumberCI: '', //*
	// todo ---------------------------------
	customerCI: '',
	customer_nickname: '',
	customer_title: '',
	customer_org_num: '',
	// todo ----------------------------------
	dispatch_address_id: '',
	invoice_address_id: '',
	invoice_exchange_rate: '',
	invoice_exchange_unit: '',
	invoice_language: '',
	invoice_type: '',
	// * -------------------------------------
	paper_invoice_fee: '',
	is_fee_addable: '',
	paper_invoice_fee_vat: '',
	paper_invoice_fee_vat_percent: '',
	// todo ----------------------------------
	invoiceGoodsTransactionsLines: [],
	creditInvoiceGoodsTransactionsLines: [],
	// todo ----------------------------------
	// ? Return Dispatch Modal state ---------
	isModalVisible: false,
	keyAddDocumentLinesModal: 0,
	isAddDisabled: true,
	disableInvoice: false, //*
	// todo ----------------------------------
	rows1: [],
	columns1: [
		{
			label: 'Delete',
			field: 'delete',
			width: '35px',
			sortable: false,
		},
		{
			label: 'Line info',
			field: 'line_info',
			width: '300px',
		},
		{
			label: 'Line Amount',
			field: 'amount_credit',
			width: '60px',
		},
		{
			label: 'Customer Price',
			field: 'invoice_price_exchange',
			width: '70px',
		},
		{
			label: 'Line Total',
			field: 'line_price_total_credit_exchange',
			width: '70px',
		},
		{
			label: 'Exc.',
			field: 'invoice_exchange_unit',
			width: '70px',
		},
	],
	// todo ----------------------------------
	columns2: [
		{
			label: 'Select',
			field: 'select',
			width: '35px',
			sortable: false,
		},
		{
			label: 'Line info',
			field: 'line_info',
			width: '300px',
		},
		{
			label: 'Line Amount',
			field: 'amount_credit',
			width: '60px',
		},
		{
			label: 'Customer Price',
			field: 'invoice_price_exchange',
			width: '70px',
		},
		{
			label: 'Line Total',
			field: 'line_price_total_credit_exchange',
			width: '70px',
		},
		{
			label: 'Exc.',
			field: 'invoice_exchange_unit',
			width: '70px',
		},
	],
})
export default function useCreditInvoiceState() {
	return {
		...toRefs(creditInvoiceState),
		creditInvoiceState,
	}
}
