<!-- @format -->

<template>
	<div>
		<div>
			<div style="background-color: rgb(76, 92, 122); padding-bottom: 30px">
				<b-button
					v-if="showSaveAllButton"
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(182, 90, 92); color: white"
					@click="onSaveAndLock"
					:disabled="disableButtons"
				>
					Save & Lock All
				</b-button>
				<b-button
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(52, 136, 206); color: white"
					@click="onGetDispatch"
					:disabled="disableGetButtons"
				>
					Get the dispatch
				</b-button>
				<b-button
					class="margin-top-30 margin-left-15 width-180"
					type="is-danger"
					outlined
					@click="onCancelAll"
				>
					{{ cancelCloseText }}
				</b-button>
				<div style="margin-left: 15px; margin-top: 20px; color: white">
					{{ documentNumbers }}
				</div>
				<div v-if="isModalDispatchVisible">
					<ModalDispatchFrame
						:onClose="onClose"
						processType="dispatch"
						:key="keyModal"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	computed,
	ref,
	reactive,
	watchEffect,
	onMounted,
	onUnmounted,
} from '@vue/composition-api'
import { useMutation } from '@vue/apollo-composable'
import Store from '@/store'
import dateformat from 'dateformat'
// ? Composables -----------------------------------------------------------------------------------------------------------------
import useReturnOrderDispatchState from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchState.js'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
// ? GraphQL -----------------------------------------------------------------------------------------------------------------
import CreateOrderDispatchNumbersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateOrderDispatchNumbers.graphql'
import CreateOrderHeadersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateOrderHeadersMutation.graphql'
import CreateDispatchHeadersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateDispatchHeadersMutation.graphql'
import TriggerStockCreditAmountsMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/TriggerStockCreditAmountsMutation.graphql'
// ? Components -----------------------------------------------------------------------------------------------------------------
import ModalDispatchFrame from '@/_srcv2/pages/_reports/get-data-and-report/dispatch/ModalDispatchFrame.vue'

export default {
	name: 'SaveTheReturnDispatch',
	components: { ModalDispatchFrame },
	setup() {
		const {
			isFormDisabled,
			// todo ---------------------------------
			companyIdRD,
			customerIdRD,
			// dispatchNumberRD,
			// todo ---------------------------------
			customerRD,
			// customer_nickname,
			// customer_title,
			// customer_org_num,
			// todo ----------------------------------
			dispatch_address_id,
			invoice_address_id,
			dispatch_exchange_rate,
			dispatch_exchange_unit,
			dispatch_language,
			dispatch_type,
			// todo ----------------------------------
			showCreateReturnOrderDispatch,
			// dispatchGoodsTransactionsLines,
			returnDispatchGoodsTransactionsLines,
		} = useReturnOrderDispatchState()
		const today = new Date()
		const orderIssueDate = ref('')
		orderIssueDate.value = dateformat(today, 'yyyy-mm-dd')
		const keyModal = ref(0)
		// todo ---------------------------------------------------------------------------
		watchEffect(() => {
			if (returnDispatchGoodsTransactionsLines.value !== null || undefined) {
				disableButtons.value =
					returnDispatchGoodsTransactionsLines.value.length <= 0
			} else {
				disableButtons.value = true
			}
		})

		// todo --------------------------------------------------------------------------------------------
		const orderNumber = ref('')
		const dispatchNumber = ref('')
		const documentNumbers = computed(() => {
			return `Order Number: ${orderNumber.value} - Dispatch Number: ${dispatchNumber.value}`
		})
		const cancelCloseText = ref('Cancel All')
		const setCancelCloseText = (text) => {
			cancelCloseText.value = text
		}
		// todo -------------------------------------------------------------------
		const disableButtons = ref(true)
		const disableGetButtons = ref(true)
		const showSaveAllButton = ref(true)
		onMounted(() => {
			setCancelCloseText('Cancel All')
			isFormDisabled.value = false
			showSaveAllButton.value = true
		})
		// todo --------------------------------------------------------------------------------
		// ? get new order and dispatch number from database
		const { mutate: getNewOrderDispatchNumbers } = useMutation(
			CreateOrderDispatchNumbersMutation,
			() => ({
				variables: {
					company_nickname: Store.getters.getUserCurrentCompany,
				},
			}),
		)
		// todo ---------------------------------------------------------------------------
		// ? Create new order header ---------------------------------------------
		// * AddNewOrderMutation
		const orderMutationVariable = computed(() => {
			return [
				{
					company_id: companyIdRD.value,
					order_number: orderNumber.value,
					order_date: orderIssueDate.value,
					order_type: dispatch_type.value,
					order_exchange_unit: dispatch_exchange_unit.value,
					order_exchange_rate: dispatch_exchange_rate.value,
					order_language: dispatch_language.value,
					customer_id: customerIdRD.value,
					dispatch_address_id: dispatch_address_id.value,
					invoice_address_id: invoice_address_id.value,
					order_lock: true,
					order_heders_goods_transactions_rel: {
						data: returnDispatchGoodsTransactionsLines.value,
					},
				},
			]
		})
		// ? --------------------------------------------------------------------------------
		const { mutate: addNewOrder, onDone: onDoneAddNewOrder } = useMutation(
			CreateOrderHeadersMutation,
			() => ({
				variables: {
					input: orderMutationVariable.value,
				},
			}),
		)
		// ? --------------------------------------------------------------------------------
		onDoneAddNewOrder((result) => console.log('result', result))
		// todo ---------------------------------------------------------------------------
		// ! Create new dispatch header ---------------------------------------------
		// * AddNewDispatchMutation
		const dispatchMutationVariable = computed(() => {
			return [
				{
					company_id: companyIdRD.value,
					customer_id: customerIdRD.value,
					dispatch_address_id: dispatch_address_id.value,
					dispatch_date: orderIssueDate.value,
					dispatch_exchange_rate: dispatch_exchange_rate.value,
					dispatch_exchange_unit: dispatch_exchange_unit.value,
					dispatch_language: dispatch_language.value,
					dispatch_lock: true,
					dispatch_number: dispatchNumber.value,
					dispatch_type: dispatch_type.value,
					invoice_address_id: invoice_address_id.value,
				},
			]
		}) // todo ------------------------------------------------------------------------
		const { mutate: addNewDispatch } = useMutation(
			CreateDispatchHeadersMutation,
			() => ({
				variables: {
					input: dispatchMutationVariable.value,
					order_number: orderNumber.value,
					dispatch_number: dispatchNumber.value,
				},
			}),
		)
		// ! ---------------------------------------------------------------------
		// * TriggerStockCreditAmountsMutation
		const mutationVariablesMutateStockCreditAmountMutation = reactive({
			company_id: companyIdRD.value,
			stock_id: '',
			credit_amount: 0,
		})
		const { mutate: mutateStockCreditAmount } = useMutation(
			TriggerStockCreditAmountsMutation,
			() => ({
				variables: mutationVariablesMutateStockCreditAmountMutation,
			}),
		)
		// * ---------------------------------------------------------------------
		const condition = true
		const setCreditAmounts = () => {
			return new Promise((resolve, reject) => {
				if (condition) {
					const linesLength = returnDispatchGoodsTransactionsLines.value.length
					for (let i = linesLength; i > 0; i--) {
						console.log(
							'line:',
							returnDispatchGoodsTransactionsLines.value[i - 1],
						)
						let item = returnDispatchGoodsTransactionsLines.value[i - 1]
						mutationVariablesMutateStockCreditAmountMutation.company_id =
							companyIdRD.value
						mutationVariablesMutateStockCreditAmountMutation.stock_id =
							item.stock_id
						mutationVariablesMutateStockCreditAmountMutation.credit_amount =
							item.dispatch_amount
						mutateStockCreditAmount()
					}
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo -----------------------------------------------------------------
		const {
			reportDocumentType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			isSelectedDocumentLocked,
			isModalVisible,
			isUnlockButtonVisible,
			reportCustomer,
			reportCustomerEmail,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
		} = useProcessReportStore()
		// todo -----------------------------------------------------------------
		const setVariablesPdfReport = (docType, docNum) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					reportDocumentType.value = docType
					reportDocumentNumber.value = docNum
					reportDocumentOurCompany.value = companyIdRD.value
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = false
					reportCustomer.value = customerRD.value
					reportCustomerEmail.value = 'evedat@gmail.com'
					reportDocumentDate.value = orderIssueDate
					reportDocumentStatus.value = 'locked'
					isUnlockButtonVisible.value = false
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo ---------------------------------------------------------------------------------------
		const setAfterSaveState = () => {
			showSaveAllButton.value = false
			disableGetButtons.value = false
			isFormDisabled.value = true
			setCancelCloseText('Close')
		}
		const onSaveAndLock = () => {
			getNewOrderDispatchNumbers()
				.then((result) => {
					const payload = ref(result.data.update_companies.returning[0])
					orderNumber.value = `${payload.value.letter_code} ${payload.value.order_number}`
					dispatchNumber.value = `${payload.value.letter_code} ${payload.value.dispatch_number}`
				})
				.then(() => {
					addNewOrder()
						.then(() => addNewDispatch())
						.then(() => setCreditAmounts())
						.then(() => {
							setAfterSaveState()
						})
				})
		}
		const { showSearchActiveCustomers } = useCreateDocumentComponentState()
		// ? -----------------------------------------------------------------
		const isModalOrderVisible = ref(false)
		const isModalDispatchVisible = ref(false)
		const onCancelAll = () => {
			showSearchActiveCustomers.value = true
			showCreateReturnOrderDispatch.value = false
		}
		// todo --------------------------------------------------------------------------------------
		const onGetDispatch = () => {
			setVariablesPdfReport('dispatch', dispatchNumber.value).then(() => {
				isModalDispatchVisible.value = true
				keyModal.value += 1
			})
		}
		// todo --------------------------------------------------------------------------------------
		const onClose = () => {
			isModalVisible.value = false
			isModalOrderVisible.value = false
			isModalDispatchVisible.value = false
		}
		// todo --------------------------------------------------------------------------------------
		onUnmounted(() => {
			// showDocumentHeadersGroup.value = false
		})
		return {
			onSaveAndLock,
			onCancelAll,
			onClose,
			documentNumbers,
			onGetDispatch,
			showSaveAllButton,
			disableGetButtons,
			disableButtons,
			cancelCloseText,
			isModalDispatchVisible,
			keyModal,
		}
	},
}
</script>

<style lang="scss" scoped></style>
