/** @format */

import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const activeCustomersState = reactive({
	columns: [
		{
			label: 'Select',
			field: 'select',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Price Class',
			field: 'customer_price_class',
			width: '75px',
		},
		{
			label: 'Customer ID',
			field: 'customer_id',
			width: '145px',
		},
		{
			label: 'Customer',
			field: 'customer',
		},
		{
			label: 'Org.Number',
			field: 'customer_org_num',
			width: '135px',
		},
		{
			label: 'Terms',
			field: 'terms',
			width: '185px',
		},
		{
			label: 'Risky',
			field: 'isRisky',
			type: 'boolean',
			width: '80px',
		},
	],
})

export default function useSearchActiveCustomers() {
	return {
		...toRefs(activeCustomersState),
	}
}
