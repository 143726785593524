<!-- @format -->

<template>
	<div>
		<div>
			<div style="background-color: rgb(76, 92, 122)">
				<div class="table-header">
					Current stock items that added to document
				</div>
			</div>
			<div :class="isFormDisabled ? 'disable-div' : 'enable-div'">
				<ve-table
					:rows="orderLinesArray"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:searchedOptions="false"
					:perPage="perPageProp"
					maxHeight="1500px"
				>
				</ve-table>
			</div>
		</div>
	</div>
</template>

<script>
import useDraftDocumentLines from '@/_srcv2/pages/admin/create-document-add-lines/draft-document-lines/useDraftDocumentLines.js'
import { ref, onMounted } from '@vue/composition-api'
export default {
	name: 'DraftDocumentLines',
	setup() {
		const perPageProp = ref(25)
		const { columnsList, orderLinesArray, isFormDisabled } =
			useDraftDocumentLines()
		const onRowDoubleClick = (params) => {
			console.log(params.row)
		}
		onMounted(() => (orderLinesArray.value = []))
		// delete order line
		const arrayRemove = (arr, value) => {
			console.log('value', value)
			return arr.filter(function (item) {
				return item.goods_transaction_id !== value
			})
		}
		const onCellClick = (params) => {
			if (params.column.field === 'delete') {
				orderLinesArray.value = arrayRemove(
					orderLinesArray.value,
					params.row.goods_transaction_id,
				)
			}
		}
		return {
			columnsList,
			perPageProp,
			onCellClick,
			onRowDoubleClick,
			orderLinesArray,
			isFormDisabled,
		}
	},
}
</script>

<style scoped lang="scss"></style>
