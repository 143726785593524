<!-- @format -->

<template>
	<div>
		<div
			style="
				height: 85px;
				margin-top: -30px;
				margin-bottom: 0px;
				color: white;
				background-color: rgb(78, 92, 123);
			"
		>
			<div
				style="
					margin-left: 20px;
					margin-top: 30px;
					padding-top: 25px;
					font-weight: 900;
				"
			>
				{{ feeMessage }}
			</div>
		</div>
		<div id="create-order-dispatch-invoice-frame" v-if="createLevel > 1">
			<div style="background-color: rgb(76, 92, 122); padding-bottom: 30px">
				<b-button
					v-if="showSaveAllButton"
					class="margin-left-15 width-180"
					style="background-color: rgb(182, 90, 92); color: white"
					@click="onSaveAndLock"
					:disabled="disableButtons"
				>
					Save & Lock All
				</b-button>
				<b-button
					class="margin-left-15 width-180"
					style="background-color: rgb(61, 196, 134); color: white"
					@click="onGetOrder"
					:disabled="disableGetButtons"
				>
					Get the order
				</b-button>
				<b-button
					class="margin-left-15 width-180"
					style="background-color: rgb(52, 136, 206); color: white"
					@click="onGetDispatch"
					:disabled="disableGetButtons"
				>
					Get the dispatch
				</b-button>
				<b-button
					class="margin-left-15 width-180"
					style="background-color: rgb(109, 65, 161); color: white"
					@click="onGetInvoice"
					:disabled="disableGetButtons"
				>
					Get the invoice
				</b-button>
				<b-button
					class="margin-left-15 width-180"
					type="is-danger"
					outlined
					@click="onCancelAll"
				>
					{{ cancelCloseText }}
				</b-button>
				<div style="margin-left: 15px; margin-top: 20px; color: white">
					{{ documentNumbers }}
				</div>
				<div v-if="isModalOrderVisible">
					<ModalOrderFrame :onClose="onClose" />
				</div>
				<div v-if="isModalDispatchVisible">
					<ModalDispatchFrame :onClose="onClose" processType="dispatch" />
				</div>
				<div v-if="isModalInvoiceVisible">
					<ModalInvoiceFrame :onClose="onClose" processType="sale-invoice" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	computed,
	ref,
	reactive,
	// watch,
	watchEffect,
	onMounted,
	onUnmounted,
} from '@vue/composition-api'
import { useMutation } from '@vue/apollo-composable'
import Store from '@/store'
import addDays from 'add-days'
import dateformat from 'dateformat'
// ? Composables -----------------------------------------------------------------------------------------------------------------
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
// import useOrderHeadersModelSchemaOptions from '@/_srcv2/pages/admin/_shared/useOrderHeadersModelSchemaOptions.js'
import useCheckRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/useCheckRisk.js'
import useDocumentHeaderState from '@/_srcv2/pages/admin/_shared/useDocumentHeaderState.js'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import useDraftDocumentLines from '@/_srcv2/pages/admin/create-document-add-lines/draft-document-lines/useDraftDocumentLines.js'
import useGetUuid from '@/_srcv2/views/_shared/_composables/create-uuid/useGetUuid.js'
import useCreditInvoiceState from '@/_srcv2/pages/sale-invoice/credit-invoice/scripts/useCreditInvoiceState.js'
// ? GraphQL -----------------------------------------------------------------------------------------------------------------
// import GetOurCompanyFeeStatusQuery from '@/_srcv2/pages/sale-invoice/graphql/GetOurCompanyFeeStatusQuery.graphql'
// import CreateOrderNumberMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateOrderNumberMutation.graphql'
// import CreateOrderDispatchNumbersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateOrderDispatchNumbers.graphql'
import CreateOrderDispatchInvoiceNumbersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateOrderDispatchInvoiceNumbers.graphql'
import CreateOrderHeadersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateOrderHeadersMutation.graphql'
import CreateDispatchHeadersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateDispatchHeadersMutation.graphql'
import CreateInvoiceHeadersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateInvoiceHeadersMutation.graphql'
import TriggerStockCreditAmountsMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/TriggerStockCreditAmountsMutation.graphql'
// ? Components -----------------------------------------------------------------------------------------------------------------
import ModalOrderFrame from '@/_srcv2/pages/_reports/get-data-and-report/order/ModalOrderFrame.vue'
import ModalDispatchFrame from '@/_srcv2/pages/_reports/get-data-and-report/dispatch/ModalDispatchFrame.vue'
import ModalInvoiceFrame from '@/_srcv2/pages/_reports/get-data-and-report/invoice/ModalInvoiceFrame.vue'
// *****************************************************************************************************************************
import numeral from 'numeral'

export default {
	name: 'SaveTheCreditInvoice',
	components: {
		ModalOrderFrame,
		ModalDispatchFrame,
		ModalInvoiceFrame,
	},
	setup() {
		// ***********************************************************************************************************************
		const {
			creditInvoiceGoodsTransactionsLines,
			dispatch_address_id,
			invoice_address_id,
			invoice_exchange_rate,
			invoice_exchange_unit,
			invoice_language,
			invoice_type,
			paper_invoice_fee,
			is_fee_addable,
			paper_invoice_fee_vat,
			// paper_invoice_fee_vat_percent,
			// invoiceGoodsTransactionsLines,
			showCreateCreditInvoice,
		} = useCreditInvoiceState()
		// todo --------------------------------------------------------------------
		const invoiceFee = reactive({
			isAppliable: is_fee_addable.value,
			invoiceFeeAmount: paper_invoice_fee,
			invoiceFeeVat: paper_invoice_fee_vat,
		})
		// todo ----------------------------------------------------------------------
		const { getUUID } = useGetUuid()
		const disableRadioButton = ref()

		onMounted(() => {
			issueDate.value = dateformat((new Date(), 'yyyy-mm-dd'))
			reportProcessType.value = 'add'
			invoiceFee.isAppliable = is_fee_addable.value
			invoiceFee.invoiceFeeAmount = paper_invoice_fee.value
			invoiceFee.invoiceFeeVat = paper_invoice_fee_vat.value
		})
		const feeMessage = computed(() => {
			if (invoiceFee.isAppliable === false) {
				return ''
			} else if (invoiceFee.isAppliable === true) {
				return `(Fee: ${numeral(invoiceFee.invoiceFeeAmount / 100).format(
					'0,0.00',
				)} kr. moms: ${numeral(invoiceFee.invoiceFeeVat / 100).format(
					'0,0.00',
				)} kr.)`
			} else {
				return 'Please select whether the will be applied or not'
			}
		})
		const {
			createLevel,
			// showAddGoodsTransactions,
			showSearchActiveCustomers,
			showDocumentHeadersGroup,
			// showGetDocumentHeadersDataComponent,
		} = useCreateDocumentComponentState()
		const { isFormDisabled } = useDraftDocumentLines()
		const { documentCustomerEmail } = useDocumentHeaderState()
		const issueDate = ref()
		console.log('+++++++++++++++++++ issueDate', issueDate.value)
		const {
			customerIdCR: customerId,
			customerTitleCR: customerTitle,
			customerNicknameCR: customerNickname,
			customerOrgNumberCR: customerOrgNum,
			term: customerTerm,
			termsCR: terms,
			showCheckRiskModule,
		} = useCheckRisk()
		const isInvoiceSendByEmail = ref()
		isInvoiceSendByEmail.value = terms.value.includes('Email') ? true : false
		// todo ----------------------------------------------------------------------------
		const isOrderLocked = ref(false)
		const ourCompany = Store.getters.getUserCurrentCompany
		const orderNumber = ref('')
		const dispatchNumber = ref('')
		const invoiceNumber = ref('')
		const documentNumbers = computed(() => {
			return `Order Number: ${orderNumber.value} - Dispatch Number: ${dispatchNumber.value} - Invoice Number: ${invoiceNumber.value}`
		})
		const cancelOrderText = ref('Cancel the Order')
		const setCancelOrderText = (text) => {
			cancelOrderText.value = text
		}
		const cancelCloseText = ref('Cancel All')
		const setCancelCloseText = (text) => {
			cancelCloseText.value = text
		}
		const convertToNumeric = (stringExc) => {
			const formattedNumber = parseFloat(stringExc)
			console.log('formattedNumber', formattedNumber)
			console.log('typeof formattedNumber', typeof formattedNumber)
			return formattedNumber
		}
		// todo -------------------------------------------------------------------
		const disableButtons = ref(true)
		const disableGetButtons = ref(true)
		const showSaveAllButton = ref(true)
		// todo -------------------------------------------------------------------
		watchEffect(() => {
			if (creditInvoiceGoodsTransactionsLines.value !== null || undefined) {
				disableButtons.value =
					creditInvoiceGoodsTransactionsLines.value.length <= 0
			} else {
				disableButtons.value = true
			}
		})
		onMounted(() => {
			setCancelCloseText('Cancel All')
			setCancelOrderText('Cancel the Order')
			isFormDisabled.value = false
			showSaveAllButton.value = true
		})
		// ? get new order number from database
		// const { mutate: getNewOrderNumber } = useMutation(
		// 	CreateOrderNumberMutation,
		// 	() => ({
		// 		variables: {
		// 			company_nickname: Store.getters.getUserCurrentCompany,
		// 		},
		// 	}),
		// )
		// todo ---------------------------------------------------------------------------
		// ? get new order, dispatch and invoice number from database
		const { mutate: getNewOrderDispatchInvoiceNumbers } = useMutation(
			CreateOrderDispatchInvoiceNumbersMutation,
			() => ({
				variables: {
					company_nickname: Store.getters.getUserCurrentCompany,
				},
			}),
		)
		// todo ---------------------------------------------------------------------------
		// ? Create new order header ---------------------------------------------
		// * AddNewOrderMutation
		const orderMutationVariable = computed(() => {
			return [
				{
					company_id: ourCompany,
					order_number: orderNumber.value,
					order_date: issueDate.value,
					order_type: invoice_type.value,
					order_exchange_unit: invoice_exchange_unit.value,
					order_exchange_rate: convertToNumeric(invoice_exchange_rate.value),
					order_language: invoice_language.value,
					customer_id: customerId.value,
					dispatch_address_id: dispatch_address_id.value,
					invoice_address_id: invoice_address_id.value,
					order_lock: isOrderLocked.value,
					_goods_transactions: {
						data: creditInvoiceGoodsTransactionsLines.value.map((item) => {
							return {
								amount_credit: item.amount_credit,
								dispatch_amount: item.dispatch_amount,
								goods_transaction_id: getUUID(),
								invoice_price: item.invoice_price,
								invoice_price_exchange: item.invoice_price_exchange,
								line_info: item.line_info,
								line_price_total_credit: item.line_price_total_credit,
								line_price_total_credit_exchange:
									item.line_price_total_credit_exchange,
								order_amount: item.order_amount,
								stock_id: item.stock_id,
								transaction_type: item.transaction_type,
								unit_price: item.unit_price,
								vat_credit: item.vat_credit,
								vat_credit_exchange: item.vat_credit_exchange,
								vat_percent: item.vat_percent,
							}
						}),
					},
				},
			]
		})
		// ? --------------------------------------------------------------------------------
		const { mutate: addNewOrder, onDone: onDoneAddNewOrder } = useMutation(
			CreateOrderHeadersMutation,
			() => ({
				variables: {
					input: orderMutationVariable.value,
				},
			}),
		)
		// ? --------------------------------------------------------------------------------
		onDoneAddNewOrder((result) => console.log('result', result))
		// todo ---------------------------------------------------------------------------
		// ! Create new dispatch header ---------------------------------------------
		// * AddNewDispatchMutation
		const dispatchMutationVariable = computed(() => {
			return [
				{
					company_id: ourCompany,
					customer_id: customerId.value,
					dispatch_address_id: dispatch_address_id.value,
					dispatch_date: issueDate.value,
					dispatch_exchange_rate: convertToNumeric(invoice_exchange_rate.value),
					dispatch_exchange_unit: invoice_exchange_unit.value,
					dispatch_language: invoice_language.value,
					dispatch_lock: true,
					dispatch_number: dispatchNumber.value,
					dispatch_type: invoice_type.value,
					invoice_address_id: invoice_address_id.value,
				},
			]
		})
		// todo ------------------------------------------------------------------------
		const { mutate: addNewDispatch } = useMutation(
			CreateDispatchHeadersMutation,
			() => ({
				variables: {
					input: dispatchMutationVariable.value,
					order_number: orderNumber.value,
					dispatch_number: dispatchNumber.value,
				},
			}),
		)
		// ! ---------------------------------------------------------------------
		// * TriggerStockCreditAmountsMutation
		const mutationVariablesMutateStockCreditAmountMutation = reactive({
			company_id: ourCompany,
			stock_id: '',
			credit_amount: 0,
		})
		const { mutate: mutateStockCreditAmount } = useMutation(
			TriggerStockCreditAmountsMutation,
			() => ({
				variables: mutationVariablesMutateStockCreditAmountMutation,
			}),
		)
		// * ---------------------------------------------------------------------
		const condition = true
		const setCreditAmounts = () => {
			return new Promise((resolve, reject) => {
				if (condition) {
					const linesLength = creditInvoiceGoodsTransactionsLines.value.length
					for (let i = linesLength; i > 0; i--) {
						console.log(
							'line:',
							creditInvoiceGoodsTransactionsLines.value[i - 1],
						)
						let item = creditInvoiceGoodsTransactionsLines.value[i - 1]
						mutationVariablesMutateStockCreditAmountMutation.company_id =
							ourCompany
						mutationVariablesMutateStockCreditAmountMutation.stock_id =
							item.stock_id
						mutationVariablesMutateStockCreditAmountMutation.credit_amount =
							item.dispatch_amount
						mutateStockCreditAmount()
					}
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo ----------------------------------------------------------------------------
		// * AddNewInvoiceMutation
		// const { getUUID } = useGetUuid();
		const invoiceMutationVariable = computed(() => {
			return {
				input: [
					{
						document_transaction_id: getUUID(),
						our_company: ourCompany,
						customer_id: customerId.value,
						invoice_number: invoiceNumber.value,
						transaction_date: issueDate.value,
						invoice_date: issueDate.value,
						invoice_due_date: dateformat(
							addDays(Date.parse(issueDate.value), customerTerm.value),
							'yyyy-mm-dd',
						),
						invoice_type: invoice_type.value,
						invoice_language: invoice_language.value,
						invoice_exchange_rate: convertToNumeric(
							invoice_exchange_rate.value,
						),
						invoice_exchange_unit: invoice_exchange_unit.value,
						invoice_address_id: invoice_address_id.value,
						transaction_type: 'sales invoice ',
						// ****************************************************************
						is_fee_addable: invoiceFee.isAppliable,
						paper_invoice_fee: invoiceFee.invoiceFeeAmount,
						paper_invoice_fee_vat: invoiceFee.invoiceFeeVat,
						paper_invoice_fee_vat_percent: 25,
					},
				],
				dispatch_number: dispatchNumber.value,
				invoice_number: invoiceNumber.value,
				our_company: ourCompany,
			}
		})
		//********************************************************************************
		const { mutate: addNewInvoice } = useMutation(
			CreateInvoiceHeadersMutation,
			() => ({
				variables: invoiceMutationVariable.value,
			}),
		)
		//********************************************************************************
		const onCancel = () => {
			// showAddGoodsTransactions.value = false
			showCheckRiskModule.value = false
			showSearchActiveCustomers.value = true
			// todo --------------------------------------------------------------------------
			showDocumentHeadersGroup.value = false
			showCreateCreditInvoice.value = false
		}
		const onClose = () => {
			isModalVisible.value = false
			isModalOrderVisible.value = false
			isModalDispatchVisible.value = false
			isModalInvoiceVisible.value = false
			onCancel()
		}
		//*********************************************************************************
		const setOrderStatus = (type) => {
			return new Promise((resolve, reject) => {
				if (type !== null || undefined) {
					switch (type) {
						case 'save':
							isOrderLocked.value = false
							break
						case 'lock':
							isOrderLocked.value = true
							break
						case 'view':
							isOrderLocked.value = true
							break
						default:
							isOrderLocked.value = false
					}
					resolve('done')
				} else {
					const reason = new Error('Action type is not found')
					reject(reason)
				}
			})
		}
		// todo -----------------------------------------------------------------
		const {
			reportDocumentType,
			reportProcessType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			isSelectedDocumentLocked,
			isModalVisible,
			isUnlockButtonVisible,
			reportCustomer,
			reportCustomerEmail,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
		} = useProcessReportStore()
		// todo -----------------------------------------------------------------
		const isModalOrderVisible = ref(false)
		const isModalDispatchVisible = ref(false)
		const isModalInvoiceVisible = ref(false)
		// const condition2 = true
		// const setVariables = () => {
		// 	return new Promise((resolve, reject) => {
		// 		if (condition2) {
		// 			reportDocumentType.value = 'order'
		// 			reportDocumentNumber.value = orderNumber.value
		// 			reportDocumentOurCompany.value = ourCompany
		// 			isSelectedDocumentLocked.value = true
		// 			isSelectedDocumentReported.value = false
		// 			reportCustomer.value = `${customerId.value} - ${customerTitle.value} - ${customerNickname.value} - ${customerOrgNum.value}`
		// 			reportDocumentDate.value = issueDate
		// 			reportDocumentStatus.value = `Order is locked and not dispatched`
		// 			isUnlockButtonVisible.value = false
		// 			resolve('ok')
		// 		} else {
		// 			const reason = new Error('Data could not be fetched from database')
		// 			reject(reason)
		// 		}
		// 	})
		// }
		// todo -----------------------------------------------------------------------------------
		const setVariablesPdfReport = (docType, docNum) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					reportDocumentType.value = docType
					reportDocumentNumber.value = docNum
					reportDocumentOurCompany.value = ourCompany
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = false
					reportCustomer.value = `${customerId.value} - ${customerTitle.value} - ${customerNickname.value} - ${customerOrgNum.value}`
					reportCustomerEmail.value = documentCustomerEmail.value
					reportDocumentDate.value = issueDate
					reportDocumentStatus.value = 'locked'
					isUnlockButtonVisible.value = false
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo ------------------------------------------------------------------------------------------------
		// const getPdfReport = () => {
		// 	setVariables().then(() => (isModalVisible.value = true))
		// }
		// todo ---------------------------------------------------------------------------------------
		// const onSave = (type) => {
		// 	setOrderStatus(type)
		// 	getNewOrderNumber()
		// 		.then((result) => {
		// 			const payload = ref(result.data.update_companies.returning[0])
		// 			orderNumber.value = `${payload.value.letter_code} ${payload.value.order_number}`
		// 		})
		// 		.then(() => {
		// 			console.log('orderMutationVariable', orderMutationVariable.value)
		// 			addNewOrder()
		// 		})
		// 		.then(() => {
		// 			if (type === 'save' || type === 'lock') {
		// 				onCancel()
		// 			} else {
		// 				setAfterSaveState()
		// 				getPdfReport()
		// 			}
		// 		})
		// }
		// todo ---------------------------------------------------------------------------------------
		// const cancelTheOrder = () => {
		// 	const alertText = computed(() => {
		// 		if (cancelOrderText.value === 'Close') {
		// 			return `Are you sure to close the window!`
		// 		} else {
		// 			return `Are you sure to cancel the Order!`
		// 		}
		// 	})
		// 	if (confirm(alertText.value)) {
		// 		onCancel()
		// 	}
		// }
		// todo --------------------------------------------------------------------------------------
		const setAfterSaveState = () => {
			showSaveAllButton.value = false
			disableGetButtons.value = false
			isFormDisabled.value = true
			setCancelCloseText('Close')
			setCancelOrderText('Close')
		}
		const onSaveAndLock = () => {
			console.log('onSaveAndLock is fired')
			console.log('orderMutationVariable', orderMutationVariable.value)
			if (createLevel.value === 4) {
				console.log('getNewOrderDispatchInvoiceNumbers is fired')
				setOrderStatus('lock')
				getNewOrderDispatchInvoiceNumbers()
					.then((result) => {
						console.log('getNewOrderDispatchInvoiceNumbers', result)
						const payload = ref(result.data.update_companies.returning[0])
						orderNumber.value = `${payload.value.letter_code} ${payload.value.order_number}`
						dispatchNumber.value = `${payload.value.letter_code} ${payload.value.dispatch_number}`
						invoiceNumber.value = `${payload.value.letter_code} ${payload.value.invoice_number}`
					})
					.then(() => {
						console.log('orderMutationVariable', orderMutationVariable.value)
						addNewOrder()
							.then(() => addNewDispatch())
							.then(() => setCreditAmounts())
							.then((payload) => {
								if (payload === 'ok') {
									console.log('dispatchNumber', dispatchNumber.value)
									console.log('invoiceNumber', invoiceNumber.value)
									console.log('ourCompany', ourCompany.value)
									addNewInvoice()
								}
							})
							.then(() => {
								setAfterSaveState()
							})
							.catch((error) => {
								console.log('=======>>>>>> Error: ', error)
							})
					})
			} else {
				alert('Please select the Create Level')
			}
			// todo ---------------------------------------------------------------------------------------
			// if (createLevel.value === 1) {
			// 	getNewOrderNumber()
			// 		.then((result) => {
			// 			const payload = ref(result.data.update_companies.returning[0])
			// 			orderNumber.value = `${payload.value.letter_code} ${payload.value.order_number}`
			// 		})
			// 		.then(() => {
			// 			addNewOrder().then(() => {
			// 				setAfterSaveState()
			// 			})
			// 		})
			// } else if (createLevel.value === 2) {
			// 	setOrderStatus('lock')
			// 	getNewOrderDispatchNumbers()
			// 		.then((result) => {
			// 			const payload = ref(result.data.update_companies.returning[0])
			// 			orderNumber.value = `${payload.value.letter_code} ${payload.value.order_number}`
			// 			dispatchNumber.value = `${payload.value.letter_code} ${payload.value.dispatch_number}`
			// 		})
			// 		.then(() => {
			// 			addNewOrder()
			// 				.then(() => addNewDispatch())
			// 				.then(() => setCreditAmounts())
			// 				.then(() => {
			// 					setAfterSaveState()
			// 				})
			// 		})
			// } else if (createLevel.value === 3) {
			// 	console.log('getNewOrderDispatchInvoiceNumbers is fired')
			// 	setOrderStatus('lock')
			// 	getNewOrderDispatchInvoiceNumbers()
			// 		.then((result) => {
			// 			console.log('getNewOrderDispatchInvoiceNumbers', result)
			// 			const payload = ref(result.data.update_companies.returning[0])
			// 			orderNumber.value = `${payload.value.letter_code} ${payload.value.order_number}`
			// 			dispatchNumber.value = `${payload.value.letter_code} ${payload.value.dispatch_number}`
			// 			invoiceNumber.value = `${payload.value.letter_code} ${payload.value.invoice_number}`
			// 		})
			// 		.then(() => {
			// 			addNewOrder()
			// 				.then(() => addNewDispatch())
			// 				.then(() => setCreditAmounts())
			// 				.then((payload) => {
			// 					if (payload === 'ok') {
			// 						console.log('dispatchNumber', dispatchNumber.value)
			// 						console.log('invoiceNumber', invoiceNumber.value)
			// 						console.log('ourCompany', ourCompany.value)
			// 						addNewInvoice()
			// 					}
			// 				})
			// 				.then(() => {
			// 					setAfterSaveState()
			// 				})
			// 				.catch((error) => {
			// 					console.log('=======>>>>>> Error: ', error)
			// 				})
			// 		})
			// } else {
			// 	alert('Please select the Create Level')
			// }
		}
		const onGetOrder = () => {
			setVariablesPdfReport('order', orderNumber.value).then(
				() => (isModalOrderVisible.value = true),
			)
		}
		// todo --------------------------------------------------------------------------------------
		const onGetDispatch = () => {
			setVariablesPdfReport('dispatch', dispatchNumber.value).then(
				() => (isModalDispatchVisible.value = true),
			)
		}
		// todo --------------------------------------------------------------------------------------
		const onGetInvoice = () => {
			setVariablesPdfReport('sale-invoice', invoiceNumber.value).then(() => {
				isModalInvoiceVisible.value = true
			})
		}
		// todo --------------------------------------------------------------------------------------
		const onCancelAll = () => {
			onCancel()
		}
		// todo --------------------------------------------------------------------------------------
		onUnmounted(() => {
			showDocumentHeadersGroup.value = false
		})
		// ***  --------------------------------------------------------------------------------
		return {
			disableRadioButton,
			numeral,
			feeMessage,
			// -----------------------
			createLevel,
			disableButtons,
			disableGetButtons,
			// onSave,
			// cancelTheOrder,
			isModalVisible,
			isModalOrderVisible,
			isModalDispatchVisible,
			isModalInvoiceVisible,
			showSaveAllButton,
			isFormDisabled,
			// ? -------------------------------------
			onSaveAndLock,
			onGetOrder,
			onGetDispatch,
			onGetInvoice,
			onCancelAll,
			// ? ---------------------------------------
			onClose,
			documentNumbers,
			cancelCloseText,
			cancelOrderText,
			// ****************************************
			// -----------------------------------------
		}
	},
}
</script>
