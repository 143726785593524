<!-- @format -->

<template>
	<div>
		<div>
			<b-button class="is-danger width-250 margin-top-20" @click="onClose"
				>X Close</b-button
			>
		</div>

		<div class="margin-top-30">
			<DocumentCustomerInfo :key="keyDocumentCustomerInfo" />
		</div>
		<div class="margin-top-30">
			<SelectOrderHeaders :key="keySelectOrderHeaders" />
		</div>
	</div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
// ? Components ------------------------------------------------------------------------------------------------------------
import DocumentCustomerInfo from '@/_srcv2/pages/admin/_shared/DocumentCustomerInfo.vue'
import SelectOrderHeaders from '@/_srcv2/pages/admin/_shared/SelectOrderHeaders.vue'
// ? Composables -----------------------------------------------------------------------------------------------------------
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
import useCheckRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/useCheckRisk.js'
import useDocumentHeaderState from '@/_srcv2/pages/admin/_shared/useDocumentHeaderState.js'

export default {
	name: 'DocumentHeadersGroup',
	components: {
		DocumentCustomerInfo,
		SelectOrderHeaders,
	},
	setup() {
		const { keySelectOrderHeaders, keyDocumentCustomerInfo } =
			useDocumentHeaderState()
		const { showModalInfoUnpaidRemindedInvoices, showCheckRiskModule } =
			useCheckRisk()
		const {
			showSearchActiveCustomers,
			showDocumentHeadersGroup,
			refreshKeyDocumentHeadersGroup,
		} = useCreateDocumentComponentState()
		// ? --------------------------------------------------------------------------------------------------
		onMounted(() => {
			console.log('DocumentHeadersGroup is initialized')
		})
		// ? ---------------------------------------------------------------------------------------------------
		const onClose = () => {
			// *
			showCheckRiskModule.value = false
			showModalInfoUnpaidRemindedInvoices.value = false
			showSearchActiveCustomers.value = true
			showDocumentHeadersGroup.value = false
			refreshKeyDocumentHeadersGroup.value += 1
		}

		return {
			onClose,
			keySelectOrderHeaders,
			keyDocumentCustomerInfo,
		}
	},
}
</script>

<style lang="scss" scoped></style>
