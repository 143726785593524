<!-- @format -->

<template>
	<div
		style="
			background-color: #4c5c7a;
			padding: 20px;
			color: white;
			padding-top: 30px;
		"
	>
		<div>
			<div style="font-size: xx-large; margin-bottom: 20px">
				Add Old Dispatches to Current Invoice
			</div>
		</div>
		<div>
			<div>
				<div v-if="isPopupVisible">
					<VPopup :closePopup="onClosePopup">
						<template #header>
							<div>
								{{ formName }}
							</div>
						</template>
						<template #body>
							<div>
								<ViewSelectedDispatch />
							</div>
						</template>
						<template #footer>
							<div>
								{{ formName }}
							</div>
						</template>
					</VPopup>
				</div>
			</div>
			<p class="text-bold">Customer's dispatches waiting to invoice</p>
			<div class="margin-bottom-30 margin-top-10">
				<vue-good-table
					id="dispatches"
					:columns="columns"
					:rows="rows"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					max-height="700px"
					:fixed-header="true"
					@on-row-dblclick="onRowDoubleClick"
					@on-cell-click="onCellClick"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [5, 10, 15, 20],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page',
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'select'">
							<b-button
								class="is-small is-success"
								:disabled="isSelectDisabled"
							>
								Select
							</b-button>
						</span>
						<span v-else-if="props.column.field === 'dispatch_total'">
							<p class="text-bold text-purple text-align-right">
								{{ numeral(props.row.dispatch_total / 100).format('0,0.00') }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'preview'">
							<b-button class="is-small is-info">Preview</b-button>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
			<p class="text-bold">Selected dispatches to invoice</p>
			<div class="margin-top-10 margin-bottom-30">
				<vue-good-table
					id="selected_dispatches"
					:columns="selectedDispatchesColumns"
					:rows="rowsSelectedDispatches"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					max-height="700px"
					:fixed-header="true"
					@on-row-dblclick="onRowDoubleClickSelectedDispatches"
					@on-cell-click="onCellClickSelectedDispatches"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [5, 10, 15, 20],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page',
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'remove'">
							<b-button
								class="is-small is-success"
								:disabled="isRemoveDisabled"
							>
								Remove
							</b-button>
						</span>
						<span v-else-if="props.column.field === 'dispatch_total'">
							<p class="text-bold text-purple text-align-right">
								{{ numeral(props.row.dispatch_total / 100).format('0,0.00') }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'preview'">
							<b-button class="is-small is-info">Preview</b-button>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
		</div>
	</div>
</template>

<script>
// * Composition APi
import { computed, reactive, ref } from '@vue/composition-api'
// * State
import useSalesInvoiceState from '@/_srcv2/pages/sale-invoice/_shared/useSalesInvoiceState.js'
// todo ---------------------------------------------------------------------------------------------
import VPopup from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/VPopup.vue'
import ViewSelectedDispatch from '@/_srcv2/pages/sale-invoice/add-invoice/ViewSelectedDispatch.vue'
import useSelectedDispatchViewState from '@/_srcv2/pages/sale-invoice/_shared/useSelectedDispatchViewState.js'
import numeral from 'numeral'

export default {
	name: 'AddOtherDispatchesToCurrentInvoice',
	components: {
		VPopup,
		ViewSelectedDispatch,
	},
	setup() {
		// todo -------------------------------------------------------------------------
		const {
			ourCompany,
			customer,
			invoiceAddress,
			dispatches,
			selectedDispatches,
			selectDispatchToInvoiceColumns,
			selectedDispatchesColumns,
			stateSalesInvoice,
			invoiceType,
		} = useSalesInvoiceState()
		// todo ----------------------------------------------------------------------
		const arrayRemove = (arr, value) => {
			console.log('value', value)
			return arr.filter(function (item) {
				return item.dispatch_number !== value
			})
		}
		// ? ----------------------------------------------------------------------
		const {
			selectedDispatchNumber,
			selectedDispatchDate,
			selectedDispatchCustomer,
			rowsSelectedDispatch,
			selectedDispatchViewState,
		} = useSelectedDispatchViewState()
		// ? ----------------------------------------------------------------------
		// * Eligible dispatches table
		const rows = computed(() => dispatches.value)
		const isSelectDisabled = ref(false)
		const saveDispatchHeaders = (params) => {
			console.log('***************** ========> params.row', params.row)
			selectedDispatchCustomer.value =
				customer.value.customer_id +
				' - ' +
				customer.value.customer_title +
				' - ' +
				customer.value.customer_nickname
			selectedDispatchDate.value = params.row.dispatch_date
			selectedDispatchNumber.value = params.row.dispatch_number
			console.log(
				'******** selectedDispatchViewState',
				selectedDispatchViewState,
			)
			rowsSelectedDispatch.value = params.row?._goods_transactions
		}
		const onSelect = (params) => {
			if (params.column.field === 'select') {
				if (params.column.field === 'select') {
					selectedDispatches.value.push(params.row)
					dispatches.value = arrayRemove(
						dispatches.value,
						params.row.dispatch_number,
					)
				}
				isSelectDisabled.value = true
				setTimeout(() => {
					isSelectDisabled.value = false
				}, 1000)
			} else if (params.column.field === 'preview') {
				saveDispatchHeaders(params)
				isPopupVisible.value = true
			}
		}
		// todo ---------------------------------------------------------------------------
		const onCellClick = (params) => {
			onSelect(params)
		}
		const onRowDoubleClick = (params) => {
			onSelect(params)
		}
		const selectedCustomer = computed(
			() =>
				`${customer.value.customer_id} - ${customer.value.customer_title} - ${customer.value.customer_nickname}`,
		)
		// todo ------------------------------------------------------------------------------
		// * Selected Dispatches Table
		const columns = computed(() => selectDispatchToInvoiceColumns.value)
		const rowsSelectedDispatches = computed(() => selectedDispatches.value)
		const isRemoveDisabled = ref(false)
		const onRemove = (params) => {
			if (params.column.field === 'remove') {
				dispatches.value.push(params.row)
				selectedDispatches.value = arrayRemove(
					selectedDispatches.value,
					params.row.dispatch_number,
				)
				isRemoveDisabled.value = true
				setTimeout(() => {
					isRemoveDisabled.value = false
				}, 1000)
			} else if (params.column.field === 'preview') {
				saveDispatchHeaders(params)
				isPopupVisible.value = true
			}
		}
		const onCellClickSelectedDispatches = (params) => {
			onRemove(params)
		}
		const onRowDoubleClickSelectedDispatches = (params) => {
			onRemove(params)
		}
		// todo -----------------------------------------------------------------------------------------
		// todo New Invoice is added to db above
		// ** -----------------------------------------------------------------------------------
		const preventButtonDoubleClick = (fn, delay, obj, method) => {
			fn(method)
			obj.isSaveAndViewDisabled = true
			setTimeout(() => (obj.isSaveAndViewDisabled = false), delay)
		}
		const disableObject = reactive({
			isSaveAndViewDisabled: false,
		})
		// todo -------------------------------------------------------------------------
		const isPopupVisible = ref(false)
		const onClosePopup = () => {
			isPopupVisible.value = false
		}
		// todo -----------------------------------------------------------
		const formName = ref('Preview Dispatch Details')
		// ** -------------------------------------------------------------
		return {
			invoiceType,
			formName,
			onClosePopup,
			isPopupVisible,
			columns,
			selectedDispatchesColumns,
			rows,
			onCellClick,
			onRowDoubleClick,
			rowsSelectedDispatches,
			onCellClickSelectedDispatches,
			onRowDoubleClickSelectedDispatches,
			ourCompany,
			invoiceAddress,
			dispatches,
			stateSalesInvoice,
			preventButtonDoubleClick,
			disableObject,
			numeral,
			isRemoveDisabled,
			isSelectDisabled,
			selectedCustomer,
		}
	},
}
</script>

<style scoped></style>
