<!-- @format -->

<template>
	<div>
		<div v-if="loading">Loading...</div>
		<div v-if="!loading">
			<ve-table
				:rows="rows"
				:columns="columns"
				:onCellClick="onCellClick"
				:onRowDoubleClick="onRowDoubleClick"
				:sort-options="sortOptions"
			>
			</ve-table>
		</div>
		<div v-if="showCheckRiskModule">
			<CheckRiskModule :key="refreshKeyCheckRiskModule" />
		</div>
	</div>
</template>

<script>
import { reactive, ref, onMounted } from '@vue/composition-api'
import Store from '@/store'
// ? GraphQL queries -----------------------------------------------------------------------------------
import GetActiveCustomerListQuery from '@/_srcv2/pages/order/graphql/GetActiveCustomerListQuery.graphql'
import { useQuery } from '@vue/apollo-composable'
// ?  Components -----------------------------------------------------------------------------------
import CheckRiskModule from '@/_srcv2/pages/_utilities/_check-risk/CheckRiskModule.vue'
// ?  Composables -----------------------------------------------------------------------------------
import useSearchActiveCustomers from '@/_srcv2/pages/admin/_shared/useSearchActiveCustomers.js'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
import useCheckRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/useCheckRisk.js'
import useDocumentHeaderState from '@/_srcv2/pages/admin/_shared/useDocumentHeaderState.js'
import useReturnOrderDispatchState from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchState.js'
import useCreditInvoiceState from '@/_srcv2/pages/sale-invoice/credit-invoice/scripts/useCreditInvoiceState.js'

export default {
	name: 'SearchActiveCustomers',
	components: { CheckRiskModule },
	setup() {
		// todo -------------------------------------------------------------------
		const {
			customerAddressArray,
			customerCountryArray,
			documentCustomerEmail,
			customerDetailsHeadersInfo,
			keyDocumentCustomerInfo,
		} = useDocumentHeaderState()
		const { customerCI } = useCreditInvoiceState()
		const { showSearchActiveCustomers } = useCreateDocumentComponentState()
		// todo -----------------------------------------------------------------
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'customer', type: 'asc' },
		}
		const options = reactive({
			fetchPolicy: 'cache-first',
		})
		const { loading, onResult, onError } = useQuery(
			GetActiveCustomerListQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// todo ------------- get rows -----------------------------------------
		const getBy = (by) => {
			if (by === 'by delivery') {
				return 'Delivery'
			} else if (by === 'by e-mail') {
				return 'Email'
			} else if (by === 'by post') {
				return 'Post'
			} else {
				return '-'
			}
		}
		const getTerms = (rule, term, by) => {
			const sendInvoiceBy = getBy(by)
			if (rule === 'monthly') {
				return `M - ${term} days - ${sendInvoiceBy}`
			} else if (rule === 'daily') {
				return `D - ${term} days - ${sendInvoiceBy}`
			} else {
				return '-'
			}
		}
		const rows = ref([])
		onError((err) => alert('Error:', err.message))
		onResult((result) => {
			rows.value = result.data.customers.map((item) => {
				return {
					isRisky: item.customer_is_risky,
					customer_price_class: item.customer_price_class,
					customer_id: item.customer_id,
					customer: item.customer_title + ' - ' + item.customer_nickname,
					customer_details: `${item.customer_id} - ${item.customer_title} - ${item.customer_nickname} - ${item.customer_org_num}`,
					customerEmail: item.customer_email,
					customer_org_num: item.customer_org_num,
					term: item.customer_term,
					terms: getTerms(
						item.customer_invoice_rule,
						item.customer_term,
						item.send_invoice_by,
					),
					addressArray: item._addresses,
					countryArray: item._addresses.map((address) => {
						return { id: address.address_id, country: address.country }
					}),
				}
			})
		})
		// todo ------------- get columns -----------------------------------------
		const { columns } = useSearchActiveCustomers()
		// todo ------------- select the customer -----------------------------------------
		const isSelectDisabled = ref(false)
		// todo ------------------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				isSelectDisabled.value = true
				setTimeout(() => {
					isSelectDisabled.value = false
				}, 1000)
				getDataFromQuery(params.row)
				console.log('params.row', params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			isSelectDisabled.value = true
			setTimeout(() => {
				isSelectDisabled.value = false
			}, 1000)
			getDataFromQuery(params.row)
		}
		// todo --------------------------------------------------
		const {
			showCheckRiskModule,
			refreshKeyCheckRiskModule,
			customerIdCR,
			customerTitleCR,
			customerNicknameCR,
			customerOrgNumberCR,
			term,
			termsCR,
			// ? ------------------------------
		} = useCheckRisk()
		const { refreshKeyEnterDispatchNumber, customerRD } =
			useReturnOrderDispatchState()
		const getDataFromQuery = (row) => {
			console.log(' ++++ getDataFromQuery is fired', row)
			documentCustomerEmail.value = row.customerEmail
			customerIdCR.value = row.customer_id
			customerTitleCR.value = row.customer_title
			customerNicknameCR.value = row.customer_nickname
			customerOrgNumberCR.value = row.customer_org_num
			term.value = row.term
			termsCR.value = row.terms
			// ? ---------------------------------------------
			customerAddressArray.value = row.addressArray
			customerCountryArray.value = row.countryArray
			// ? ---------------------------------------------
			showCheckRiskModule.value = true
			refreshKeyCheckRiskModule.value += 1
			// ? ---------------------------------------------
			customerRD.value = row.customer_details
			customerCI.value = row.customer_details
			refreshKeyEnterDispatchNumber.value += 1
			// ? ---------------------------------------------
			customerDetailsHeadersInfo.value = row.customer_details
			keyDocumentCustomerInfo.value += 1
		}
		// todo --------------------------------------------------
		onMounted(() => {})
		return {
			loading,
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			sortOptions,
			showSearchActiveCustomers,
			isSelectDisabled,
			// ? ------------------------------
			showCheckRiskModule,
			refreshKeyCheckRiskModule,
		}
	},
}
</script>

<style scoped>
.risky {
	color: red;
}
.not-risky {
	color: blue;
}
.th-stuff {
	color: red;
}
</style>
