<!-- @format -->

<template>
	<div>
		<section class="margin-top-30">
			<b-field label="Our Company">
				<b-input v-model="ourCompany" placeholder="Disabled" disabled></b-input>
			</b-field>

			<b-field label="Customer">
				<b-input
					v-model="customerDetails"
					placeholder="Disabled"
					disabled
				></b-input>
			</b-field>

			<b-field label="Invoice Number (format XX 123456)">
				<b-input
					v-model="invoiceNumber"
					placeholder="Please Enter the dispatch number needs to be create returns for"
					:disabled="disableInvoice"
					@keyup.native.enter="onContinue"
					autofocus
				></b-input>
			</b-field>
		</section>
		<div v-if="!disableInvoice">
			<b-button
				:disabled="disableSubmit"
				type="is-success"
				class="width-250 margin-top-30"
				@click="onContinue"
			>
				Continue
			</b-button>
			<b-button
				type="is-danger"
				class="width-250 margin-top-30 margin-left-30"
				@click="onClose"
			>
				Close
			</b-button>
		</div>
	</div>
</template>

<script>
import useCreditInvoiceState from '@/_srcv2/pages/sale-invoice/credit-invoice/scripts/useCreditInvoiceState.js'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
import {
	ref,
	// reactive,
	watchEffect,
	computed,
	onMounted,
} from '@vue/composition-api'
export default {
	name: 'EnterInvoiceNumber',
	setup() {
		const { showSearchActiveCustomers } = useCreateDocumentComponentState()
		const {
			invoiceNumberCI,
			companyIdCI,
			customerCI,
			showGetCustomerInvoiceByNumber,
			refreshKeyGetCustomerInvoiceByNumber,
			disableInvoice,
			showCreateCreditInvoice,
			creditInvoiceState,
		} = useCreditInvoiceState()
		console.log('From EnterInvoiceNumber customerCI.value', customerCI.value)
		const ourCompany = ref(companyIdCI.value)
		const customerDetails = ref(customerCI.value)
		const invoiceNumber = ref(invoiceNumberCI.value)
		// const disableInvoice = ref(false)
		onMounted(() => {
			console.log('onMounted creditInvoiceState', creditInvoiceState)
			disableInvoice.value = false
			invoiceNumberCI.value = ''
			invoiceNumber.value = ''
		})
		// ? ------------------------------------------------------
		const disableSubmit = computed(() => {
			return invoiceNumber.value.length < 9
		})
		watchEffect(() => {
			if (invoiceNumber.value.length === 2) {
				invoiceNumber.value = invoiceNumber.value + ' '
			} else if (invoiceNumber.value.length === 10) {
				invoiceNumber.value = invoiceNumber.value.slice(0, -1)
			}
			invoiceNumber.value = invoiceNumber.value.toUpperCase()
		})

		const regexPattern = /^[A-Za-z]{2}\s\d{6}$/
		function testRegex(variable) {
			return regexPattern.test(variable)
		}
		const onContinue = () => {
			console.log('Submit')
			if (testRegex(invoiceNumber.value)) {
				console.log('Valid')
				invoiceNumberCI.value = invoiceNumber.value
				disableInvoice.value = true
				showGetCustomerInvoiceByNumber.value = true
				refreshKeyGetCustomerInvoiceByNumber.value += 1
			} else {
				console.log('Invalid')
				alert(`
          Invalid dispatch number format!
          Please try again and write it in correct format!`)
			}
		}
		// todo -------------------------------------------------------
		const onClose = () => {
			showCreateCreditInvoice.value = false
			showSearchActiveCustomers.value = true
		}
		return {
			ourCompany,
			customerDetails,
			invoiceNumber,
			onContinue,
			onClose,
			disableSubmit,
			disableInvoice,
		}
	},
}
</script>

<style lang="scss" scoped></style>
