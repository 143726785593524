<!-- @format -->

<template>
	<div>
		<div>
			<div class="margin-top-30">
				<CreditInvoiceLines />
			</div>
			<div>
				<SaveTheCreditInvoice />
			</div>
			<div class="margin-top-30">
				<InvoiceToSelectItemFrom />
			</div>
		</div>
	</div>
</template>

<script>
import SaveTheCreditInvoice from '@/_srcv2/pages/sale-invoice/credit-invoice/components/SaveTheCreditInvoice.vue'
import InvoiceToSelectItemFrom from '@/_srcv2/pages/sale-invoice/credit-invoice/components/select-invoice-lines/InvoiceToSelectItemFrom.vue'
import CreditInvoiceLines from '@/_srcv2/pages/sale-invoice/credit-invoice/components/select-invoice-lines/CreditInvoiceLines.vue'

export default {
	name: 'TheInvoiceToSelectItemToBeCredited',
	components: {
		CreditInvoiceLines,
		SaveTheCreditInvoice,
		InvoiceToSelectItemFrom,
	},
}
</script>

<style lang="scss" scoped></style>
