/** @format */

import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'

Vue.use(VueCompositionApi)
// ? mini store
const state = reactive({
	rowsListUnpaidCR: [],
	customerIdCR: '',
	customerTitleCR: '',
	customerNicknameCR: '',
	customerOrgNumberCR: '',
	customerIsRiskyCR: false,
	term: 0,
	termsCR: '',
	unpaidRemindedInvoiceAmount: 0,
	// ? --------------------------------------------
	showCheckCustomerRisk: false,
	refreshKeyCheckCustomerRisk: 0,
	showDecideComponent: false,
	refreshKeyDecideComponent: 0,
	showModalInfoUnpaidRemindedInvoices: false,
	refreshKeyModalInfoUnpaidRemindedInvoices: 0,
	// ? --------------------------------------------
	showCheckRiskModule: false,
	refreshKeyCheckRiskModule: 0,
	// ? ---------------------------------------------
	columnsList: [
		{
			label: 'Invoice Number',
			field: 'invoiceNumber',
			width: '115px',
			type: 'number',
		},
		{
			label: 'Invoice Date',
			field: 'invoiceDate',
			type: 'date',
			dateInputFormat: 'yyyy-MM-dd',
			dateOutputFormat: 'yyyy-MM-dd',
			width: '125px',
		},
		{
			label: 'Invoice Due Date',
			field: 'invoiceDueDate',
			type: 'date',
			dateInputFormat: 'yyyy-MM-dd',
			dateOutputFormat: 'yyyy-MM-dd',
			width: '125px',
		},
		{
			label: 'Balance',
			field: 'invoiceBalance',
			width: '140px',
			html: true,
		},
		{
			label: 'Reminder Number',
			field: 'reminderId',
			type: 'number',
			width: '115px',
		},
		{
			label: 'Reminder Date',
			field: 'reminderDate',
			type: 'date',
			dateInputFormat: 'yyyy-MM-dd',
			dateOutputFormat: 'yyyy-MM-dd',
			width: '125px',
		},
		{
			label: 'Reminder Due Date',
			field: 'reminderDueDate',
			type: 'date',
			dateInputFormat: 'yyyy-MM-dd',
			dateOutputFormat: 'yyyy-MM-dd',
			width: '125px',
		},
	],
})

export default function useCheckRisk() {
	return {
		...toRefs(state),
	}
}
