<!-- @format -->

<template>
	<div>
		<div v-if="loading">Checking if the customer is risky or not...</div>
	</div>
</template>

<script>
import { reactive, onMounted } from '@vue/composition-api'
import CheckCustomerRiskQuery from './CheckCustomerRiskQuery.graphql'
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'
import storeAccounting from '@/_srcv2/pages/_pages-store/storeShared'
import useCheckRisk from './useCheckRisk.js'

export default {
	name: 'CheckCustomerRisk',
	setup() {
		console.log('CheckCustomerRisk initialized')
		const {
			customerIdCR,
			rowsListUnpaidCR,
			customerTitleCR,
			customerNicknameCR,
			customerIsRiskyCR,
			unpaidRemindedInvoiceAmount,
			showCheckCustomerRisk,
			showDecideComponent,
			refreshKeyDecideComponent,
		} = useCheckRisk()
		console.log('customerIdCR', customerIdCR.value)
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult, onError, loading } = useQuery(
			CheckCustomerRiskQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				customer_id: customerIdCR.value,
				due_date: Store.getters.getDueDate,
			}),
			options,
		)
		const { roundedCurrencyAddedNumber } = storeAccounting()
		//todo ------------------------------------------------------
		const init = (result) => {
			return new Promise((resolve, reject) => {
				if (result) {
					console.log('result', result)
					const customer = result.data.customers[0]
					customerIdCR.value = customer.customer_id
					customerTitleCR.value = customer.customer_title
					customerNicknameCR.value = customer.customer_nickname
					customerIsRiskyCR.value = customer.customer_is_risky
					unpaidRemindedInvoiceAmount.value =
						result.data.reminder_headers_aggregate.aggregate.count
					rowsListUnpaidCR.value = result.data.reminder_headers.map((item) => {
						return {
							reminderId: item.reminder_id,
							reminderDate: item.reminder_date,
							reminderDueDate: item.reminder_due_date,
							invoiceNumber:
								item.reminder_headers_reminder_lines_rel[0].invoice_number,
							invoiceDate:
								item.reminder_headers_reminder_lines_rel[0].invoice_date,
							invoiceDueDate:
								item.reminder_headers_reminder_lines_rel[0].invoice_due_date,
							invoiceBalance: roundedCurrencyAddedNumber(
								item.reminder_headers_reminder_lines_rel[0].invoice_balance,
							),
						}
					})
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		onError((err) => {
			console.log('error', err)
		})
		onResult((result) => {
			init(result).then((payload) => {
				if (payload === 'ok') {
					console.log('customerIdCR', customerIdCR.value)
					console.log('customerTitleCR', customerTitleCR.value)
					console.log('customerNicknameCR', customerNicknameCR.value)
					console.log('customerIsRiskyCR', customerIsRiskyCR.value)
					console.log('rowsListUnpaidCR', rowsListUnpaidCR.value)
					console.log(
						'unpaidRemindedInvoiceAmount',
						unpaidRemindedInvoiceAmount.value,
					)
					showCheckCustomerRisk.value = false
					showDecideComponent.value = true
					refreshKeyDecideComponent.value += 1
				}
			})
		})
		onMounted(() => {
			console.log('CheckCustomerRisk onMounted')
		})
		return {
			loading,
		}
	},
}
</script>

<style lang="scss" scoped></style>
