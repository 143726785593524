// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const currentDocumentState = reactive({
	isFormDisabled: false,
	keyGetData: 1,
	orderLinesArray: [],
	columnsList: [
		{
			label: 'Delete',
			field: 'delete',
			width: '35px',
			sortable: false,
		},
		{
			label: 'Line info',
			field: 'line_info',
			width: '300px',
		},
		{
			label: 'Line Amount',
			field: 'amount_credit',
			width: '60px',
		},
		{
			label: 'Customer Price',
			field: 'invoice_price_exchange',
			width: '70px',
		},
		{
			label: 'Line Total',
			field: 'line_price_total_credit_exchange',
			width: '70px',
		},
	],
})

export default function useDraftDocumentLines() {
	return {
		...toRefs(currentDocumentState),
	}
}
