<!-- @format -->

<template>
	<div>
		<section>
			<b-field label="Our Company">
				<b-input v-model="ourCompany" placeholder="Disabled" disabled></b-input>
			</b-field>

			<b-field label="Customer">
				<b-input
					v-model="customerDetails"
					placeholder="Disabled"
					disabled
				></b-input>
			</b-field>

			<b-field label="Dispatch Number (format XX 123456)">
				<b-input
					v-model="dispatchNumber"
					placeholder="Please Enter the dispatch number needs to be create returns for"
					:disabled="disableDispatch"
					@keyup.native.enter="onSave"
					autofocus
				></b-input>
			</b-field>
		</section>
		<div v-if="!disableDispatch">
			<b-button
				:disabled="disableSubmit"
				type="is-success"
				class="width-250 margin-top-30"
				@click="onSave"
			>
				Continue
			</b-button>
			<b-button
				type="is-danger"
				class="width-250 margin-top-30 margin-left-30"
				@click="onClose"
			>
				Close
			</b-button>
		</div>
	</div>
</template>

<script>
import useReturnOrderDispatchState from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchState.js'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
import {
	ref,
	// reactive,
	watchEffect,
	computed,
	onMounted,
} from '@vue/composition-api'
export default {
	name: 'EnterDispatchNumber',
	setup() {
		const { showSearchActiveCustomers } = useCreateDocumentComponentState()
		const {
			companyIdRD,
			customerRD,
			dispatchNumberRD,
			showGetCustomerDispatchByNumber,
			refreshKeyGetCustomerDispatchByNumber,
			disableDispatch,
			showCreateReturnOrderDispatch,
		} = useReturnOrderDispatchState()
		const ourCompany = ref(companyIdRD.value)
		const customerDetails = ref(customerRD.value)
		const dispatchNumber = ref(dispatchNumberRD.value)
		// const disableDispatch = ref(false)
		onMounted(() => {
			console.log('onMounted')
			disableDispatch.value = false
			dispatchNumberRD.value = ''
			dispatchNumber.value = ''
		})
		// ? ------------------------------------------------------
		const disableSubmit = computed(() => {
			return dispatchNumber.value.length < 9
		})
		watchEffect(() => {
			if (dispatchNumber.value.length === 2) {
				dispatchNumber.value = dispatchNumber.value + ' '
			} else if (dispatchNumber.value.length === 10) {
				dispatchNumber.value = dispatchNumber.value.slice(0, -1)
			}
			dispatchNumber.value = dispatchNumber.value.toUpperCase()
		})

		const regexPattern = /^[A-Za-z]{2}\s\d{6}$/
		function testRegex(variable) {
			return regexPattern.test(variable)
		}
		const onSave = () => {
			console.log('Submit')
			if (testRegex(dispatchNumber.value)) {
				console.log('Valid')
				dispatchNumberRD.value = dispatchNumber.value
				disableDispatch.value = true
				refreshKeyGetCustomerDispatchByNumber.value += 1
				showGetCustomerDispatchByNumber.value = true
			} else {
				console.log('Invalid')
				alert(`
          Invalid dispatch number format!
          Please try again and write it in correct format!`)
			}
		}
		// todo -------------------------------------------------------
		const onClose = () => {
			showCreateReturnOrderDispatch.value = false
			showSearchActiveCustomers.value = true
		}
		return {
			ourCompany,
			customerDetails,
			dispatchNumber,
			onSave,
			onClose,
			disableSubmit,
			disableDispatch,
		}
	},
}
</script>

<style lang="scss" scoped></style>
