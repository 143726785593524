/** @format */

import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import { reactive, toRefs } from '@vue/composition-api'
// import Store from '@/store'

const returnOrderDispatchState = reactive({
	refreshKeyCreateReturnOrderDispatch: 0,
	showCreateReturnOrderDispatch: false,
	showEnterDispatchNumber: false,
	refreshKeyEnterDispatchNumber: 0,
	refreshKeyGetCustomerDispatchByNumber: 0,
	showGetCustomerDispatchByNumber: false,
	refreshKeyTheDispatchToSelectItemToReturn: 0,
	showTheDispatchToSelectItemToReturn: false,
	isFormDisabled: false,
	// todo ---------------------------------
	companyIdRD: '',
	customerIdRD: '',
	dispatchNumberRD: '',
	// todo ---------------------------------
	customerRD: '',
	customer_nickname: '',
	customer_title: '',
	customer_org_num: '',
	// todo ----------------------------------
	dispatch_address_id: '',
	invoice_address_id: '',
	dispatch_exchange_rate: '',
	dispatch_exchange_unit: '',
	dispatch_language: '',
	dispatch_type: '',
	// todo ----------------------------------
	dispatchGoodsTransactionsLines: [],
	returnDispatchGoodsTransactionsLines: [],
	// todo ----------------------------------
	// ? Return Dispatch Modal state ---------
	isModalVisible: false,
	keyAddDocumentLinesModal: 0,
	isAddDisabled: true,
	disableDispatch: false,
	// todo ----------------------------------
	rows1: [],
	columns1: [
		{
			label: 'Delete',
			field: 'delete',
			width: '35px',
			sortable: false,
		},
		{
			label: 'Line info',
			field: 'line_info',
			width: '300px',
		},
		{
			label: 'Line Amount',
			field: 'amount_credit',
			width: '60px',
		},
		{
			label: 'Customer Price',
			field: 'invoice_price_exchange',
			width: '70px',
		},
		{
			label: 'Line Total',
			field: 'line_price_total_credit_exchange',
			width: '70px',
		},
	],
	// todo ----------------------------------
	columns2: [
		{
			label: 'Select',
			field: 'select',
			width: '35px',
			sortable: false,
		},
		{
			label: 'Line info',
			field: 'line_info',
			width: '300px',
		},
		{
			label: 'Line Amount',
			field: 'amount_credit',
			width: '60px',
		},
		{
			label: 'Customer Price',
			field: 'invoice_price_exchange',
			width: '70px',
		},
		{
			label: 'Line Total',
			field: 'line_price_total_credit_exchange',
			width: '70px',
		},
		{
			label: 'Exc.',
			field: 'dispatch_exchange_unit',
			width: '70px',
		},
	],
})
export default function useReturnOrderDispatchState() {
	return {
		...toRefs(returnOrderDispatchState),
		returnOrderDispatchState,
	}
}
