<!-- @format -->

<template>
	<div>
		<div>
			<div class="margin-top-30">
				<div style="background-color: rgb(76, 92, 122)">
					<div class="table-title">{{ formTitle1 }}</div>
				</div>
				<div :class="isFormDisabled ? 'disable-div' : 'enable-div'">
					<ve-table
						:rows="returnDispatchGoodsTransactionsLines"
						:columns="columns1"
						:onCellClick="onCellClick1"
						:onRowDoubleClick="onRowDoubleClick1"
						:searchedOptions="false"
						:perPage="perPageProp"
						maxHeight="1500px"
					>
					</ve-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import useReturnOrderDispatchState from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchState.js'
import { ref, computed, onMounted } from '@vue/composition-api'

export default {
	name: 'ReturnDispatchLines',
	setup() {
		// todo -----------------------------------------------------------------
		const {
			columns1,
			returnDispatchGoodsTransactionsLines,
			dispatch_type,
			dispatch_language,
			dispatch_exchange_rate,
			dispatch_exchange_unit,
			isFormDisabled,
		} = useReturnOrderDispatchState()
		// todo -----------------------------------------------------------------
		const formTitle1 = computed(() => {
			return `
	       The items to be added to return dispatch - ${dispatch_type.value} - ${dispatch_language.value} - (1 kr. = ${dispatch_exchange_rate.value} ${dispatch_exchange_unit.value})
	       `
		})
		const perPageProp = ref(20)
		onMounted(() => {
			returnDispatchGoodsTransactionsLines.value = []
		})
		const arrayRemove = (arr, value) => {
			console.log('value', value)
			return arr.filter(function (item) {
				return item.goods_transaction_id !== value
			})
		}
		const onCellClick1 = (params) => {
			if (params.column.field === 'delete') {
				returnDispatchGoodsTransactionsLines.value = arrayRemove(
					returnDispatchGoodsTransactionsLines.value,
					params.row.goods_transaction_id,
				)
			}
		}
		const onRowDoubleClick1 = (params) => {
			console.log(params.row)
		}
		// ? ---------------------------------------------------
		// todo -----------------------------------------------------------------
		return {
			returnDispatchGoodsTransactionsLines,
			columns1,
			formTitle1,
			perPageProp,
			onCellClick1,
			onRowDoubleClick1,
			isFormDisabled,
		}
	},
}
</script>

<style lang="scss" scoped></style>
