<!-- @format -->

<template>
	<div>
		{{ showExc }}{{ typeofshowexc }}
		<section>
			<b-field label="Select a date">
				<b-datepicker
					v-model="selectedDate"
					:show-week-number="showWeekNumber"
					placeholder="Type or select a date..."
					icon="calendar-today"
					:locale="locale"
					:first-day-of-week="firstDayOfWeek"
					editable
					trap-focus
					:disabled="isDatePickerDisabled"
				>
				</b-datepicker>
			</b-field>
		</section>
		<section>
			<form id="form" class="margin-top-20">
				<vue-form-generator
					:schema="schemaOrder"
					:model="modelOrder"
					:options="formOptionsOrder"
					@validated="onFormValidated"
				>
				</vue-form-generator>
			</form>
		</section>
		<section>
			<b-button
				class="is-success width-250"
				:disabled="!!$store.getters.getDisableSaveOrderHeaders"
				@click="onSubmit"
				>Continue</b-button
			>
		</section>
	</div>
</template>

<script>
import Store from '@/store'
import {
	onMounted,
	ref,
	watch,
	watchEffect,
	computed,
} from '@vue/composition-api'
// ? Composables -----------------------------------------------------------------------------------------------------------
import useOrderHeadersModelSchemaOptions from '@/_srcv2/pages/admin/_shared/useOrderHeadersModelSchemaOptions.js'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
import useDocumentModalState from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/useDocumentModalState.js'
import useDocumentHeaderState from '@/_srcv2/pages/admin/_shared/useDocumentHeaderState.js'
import useCountriesApi from '@/_srcv2/components/_shared/_countries-list/useCountriesApi.js'

import useSalesInvoiceState from '@/_srcv2/pages/sale-invoice/_shared/useSalesInvoiceState.js'
import useCheckRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/useCheckRisk.js'
// ? Plugins --------------------------------------------------------------------------------------------------------------
import dateformat from 'dateformat'

export default {
	name: 'SelectOrderHeaders',
	setup() {
		const { variablesQ1 } = useSalesInvoiceState()
		const { keyModal } = useDocumentModalState()
		const { orderIssueDate, customerAddressArray, customerCountryArray } =
			useDocumentHeaderState()
		const { customerIdCR: customerId } = useCheckRisk()
		// todo -------------------------------------------------------------------------------------
		function getFirstDayOfYear() {
			const currentYear = new Date().getFullYear() // Get the current year
			const firstDay = new Date(currentYear, 0, 1) // Month 0 is January, day 1 is the 1st
			return dateformat(firstDay, 'yyyy-mm-dd')
		}
		// ! Example usage
		const firstDayOfThisYear = getFirstDayOfYear()
		console.log(firstDayOfThisYear)
		// todo -------------------------------------------------------------------------------------
		console.log('customerCountryArray', customerCountryArray.value)
		const getCountryOfSelectedInvoiceAddress = (array, id) => {
			let filteredAddress = []
			for (let i = 0; i < array.length; i++) {
				if (array[i].id === id) {
					filteredAddress = [...filteredAddress, array[i]]
				}
			}
			return filteredAddress[0].country
		}

		const {
			modelOrder,
			schemaOrder,
			formOptionsOrder,
			selectedDate,
			isDatePickerDisabled,
		} = useOrderHeadersModelSchemaOptions()
		const { euCountries } = useCountriesApi()
		watchEffect(() => {
			if (
				modelOrder.invoiceAddressId === undefined ||
				modelOrder.invoiceAddressId === null ||
				modelOrder.invoiceAddressId === ''
			) {
				return
			} else {
				let result =
					getCountryOfSelectedInvoiceAddress(
						customerCountryArray.value,
						modelOrder.invoiceAddressId,
					) || 'Sverige'
				console.log('8888888888 result', result)
				if (result === undefined || null) {
					return
				} else if (result === 'Sverige') {
					modelOrder.orderType = 'Inland'
					modelOrder.orderExchangeUnit = 'kr.'
					modelOrder.orderExchangeRate = 1.0
					modelOrder.showExchangeFieldsOnForm = false
				} else if (euCountries.value.includes(result)) {
					modelOrder.orderType = 'Inside EU'
					modelOrder.showExchangeFieldsOnForm = true
				} else {
					modelOrder.orderType = 'Outside EU'
					modelOrder.showExchangeFieldsOnForm = true
				}
			}
		})
		// ? ---------------------------------------------------------------
		const locale = ref('sv-SE')
		selectedDate.value = ref(Store.getters.getToday)
		const firstDayOfWeek = ref(1)
		// ? ----------------------------------------------------
		watch(
			() => selectedDate.value,
			(value, prevValue) => {
				console.log('From watcher value', value)
				console.log('From watcher dateformat', dateformat(value, 'yyyy-mm-dd'))
				orderIssueDate.value = dateformat(value, 'yyyy-mm-dd')
				console.log('selectedDate: ', value, prevValue)
				console.log('orderIssueDate', orderIssueDate.value)
			},
		)
		const showWeekNumber = ref(true)
		// ? ---------------------------------------------------------------
		Store.dispatch('setDisableSaveOrderHeaders', false)
		const onFormValidated = (isValid, errors) => {
			if (isValid === true) {
				Store.dispatch('setDisableSaveOrderHeaders', false)
			} else if (errors.length !== 0) {
				Store.dispatch('setDisableSaveOrderHeaders', true)
			}
		}
		watchEffect(() => {
			console.log('onFormValidated is fired')
			console.log(
				'modelOrder.dispatchAddressId.length',
				modelOrder.dispatchAddressId.length,
			)
			console.log(
				'modelOrder.invoiceAddressId.length',
				modelOrder.invoiceAddressId.length,
			)
			if (
				modelOrder.dispatchAddressId.length < 1 ||
				modelOrder.invoiceAddressId.length < 1
			) {
				Store.dispatch('setDisableSaveOrderHeaders', true)
			} else {
				Store.dispatch('setDisableSaveOrderHeaders', false)
			}
		})
		// ? ----------------------------------------------------------------
		const {
			showDocumentHeadersGroup,
			showAddGoodsTransactions,
			refreshKeyAddGoodsTransactions,
		} = useCreateDocumentComponentState()
		// todo on level 4 get dispatches add to invoice query variables --------------------------------
		variablesQ1.value = {
			company_id: '',
			customer_id: '',
			invoice_address_id: '',
			dispatch_exchange_rate: 1,
			dispatch_exchange_unit: 'kr.',
			dispatch_type: 'Inland',
			bd: '',
		}
		const setQueryVariables = (item) => {
			return new Promise((resolve, reject) => {
				if (item !== null || undefined) {
					console.log('customerId.value beginning', customerId.value)
					variablesQ1.value.company_id = 'symbol' // Store.getters.getUserCurrentCompany
					variablesQ1.value.customer_id = customerId.value
					variablesQ1.value.invoice_address_id = item.invoiceAddressId
					variablesQ1.value.dispatch_exchange_rate = item.orderExchangeRate
					variablesQ1.value.dispatch_exchange_unit = item.orderExchangeUnit
					variablesQ1.value.dispatch_type = item.orderType
					variablesQ1.value.bd = firstDayOfThisYear
					console.log('variablesQ1.value', variablesQ1.value)
					resolve('Done')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo -----------------------------------------------------------------------------------------
		const onSubmit = () => {
			if (
				confirm(
					`Are you sure order's type is ${modelOrder.orderType} \n To continue to create order press 'ok' \n To cancel press 'cancel'`,
				)
			) {
				setQueryVariables(modelOrder).then(() => {
					showDocumentHeadersGroup.value = false
					showAddGoodsTransactions.value = true
					refreshKeyAddGoodsTransactions.value += 1
					keyModal.value += 1
					// showDocumentHeadersGroup.value = false
					// showAddGoodsTransactions.value = true
					// refreshKeyAddGoodsTransactions.value += 1
					// keyModal.value += 1
				})
			}
		}
		// ? --------------------------------------------------------
		const setAddressDefaultValues = (array) => {
			if (array.length === 1) {
				modelOrder.dispatchAddressId = array[0].id
				modelOrder.invoiceAddressId = array[0].id
			}
		}
		const condition = true
		const compPromise = () => {
			console.log('compPromise is initialized')
			return new Promise((resolve, reject) => {
				if (condition) {
					const array = ref([])
					array.value = customerAddressArray.value.map((item) => {
						return {
							name: `${item.address_nickname} ${item.line_1} ${item.line_2} ${item.post_code} ${item.city}`,
							id: item.address_id,
						}
					})
					// ? ---------------------------------------------------------------------------------------------
					console.log('array.value.length', array.value.length)
					resolve(array.value)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		onMounted(() => {
			console.log(
				'************************ variablesQ1.value onMounted is fired',
			)
			console.log(
				'************************ variablesQ1.value onMounted',
				variablesQ1.value,
			)
			isDatePickerDisabled.value = false
			compPromise().then((array) => {
				setAddressDefaultValues(array)
			})

			modelOrder.orderType = 'Inland'
			modelOrder.orderLanguage = 'se'
			modelOrder.orderExchangeUnit = 'kr.'
			modelOrder.orderExchangeRate = 1.0
			modelOrder.dispatchAddressId = ''
			modelOrder.invoiceAddressId = ''
			Store.dispatch('setDisableSaveOrderHeaders', true)
			alert(
				'Are you sure to create inland order \n If not change  the order type!',
			)
		})
		const showExc = computed(() => modelOrder.orderExchangeRate)
		const typeofshowexc = computed(() => typeof modelOrder.orderExchangeRate)
		return {
			locale,
			firstDayOfWeek,
			selectedDate,
			isDatePickerDisabled,
			showWeekNumber,
			modelOrder,
			schemaOrder,
			formOptionsOrder,
			onFormValidated,
			onSubmit,
			showExc,
			typeofshowexc,
		}
	},
}
</script>

<style scoped></style>
