<!-- @format -->

<template>
	<div>GetDocumentHeadersDataComponent</div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import useDocumentHeaderState from '@/_srcv2/pages/admin/_shared/useDocumentHeaderState.js'
import useOrderHeadersModelSchemaOptions from '@/_srcv2/pages/admin/_shared/useOrderHeadersModelSchemaOptions.js'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'

export default {
	name: 'GetDocumentHeadersDataComponent',
	setup() {
		const {
			showGetDocumentHeadersDataComponent,
			showDocumentHeadersGroup,
			refreshKeyDocumentHeadersGroup,
		} = useCreateDocumentComponentState()
		const {
			customerAddressArray,
			isDatePickerDisabled,
			keySelectOrderHeaders,
		} = useDocumentHeaderState()
		const { schemaOrder } = useOrderHeadersModelSchemaOptions()

		const condition = true
		const compPromise = () => {
			console.log('compPromise is initialized')
			return new Promise((resolve, reject) => {
				if (condition) {
					const array = ref([])
					array.value = customerAddressArray.value.map((item) => {
						return {
							name: `${item.address_nickname} ${item.line_1} ${item.line_2} ${item.post_code} ${item.city} ${item.state} ${item.country}`,
							id: item.address_id,
						}
					})
					// ? ---------------------------------------------------------------------------------------------
					console.log('array.value.length', array.value.length)
					resolve(array.value)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}

		onMounted(() => {
			console.log('GetDocumentHeadersDataComponent is initialized')
			compPromise()
				.then((payload) => {
					isDatePickerDisabled.value = false
					schemaOrder.groups[0].fields[4].values = payload
					schemaOrder.groups[0].fields[5].values = payload
				})
				.then(() => {
					showGetDocumentHeadersDataComponent.value = false
					showDocumentHeadersGroup.value = true
					refreshKeyDocumentHeadersGroup.value += 1
					keySelectOrderHeaders.value += 1
					keySelectOrderHeaders.value += 1
				})
		})
	},
}
</script>

<style lang="scss" scoped></style>
