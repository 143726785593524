<!-- @format -->

<template>
	<div>
		<form id="form" class="margin-top-15">
			<span class="exchange-title">All the prices are in</span>
			<span class="exchange-unit">{{ excUnit }}</span
			><br />
			<span class="exchange-title">1 kr.=</span>
			<span class="exchange-unit"
				>{{ excRateToFixed10 }} &nbsp; {{ excUnit }}</span
			>
			<vue-form-generator
				:schema="schemaOrderLines"
				:model="modelOrderLines"
				:options="formOptionsOrderLines"
				@validated="onFormValidated"
			>
			</vue-form-generator>
		</form>
		<div
			style="display: flex; justify-content: space-between"
			class="margin-top-15"
		>
			<b-button
				class="is-success width-200"
				type="submit"
				@click="resetPrices"
				v-if="true"
				>Reset the Prices
			</b-button>
			<b-button
				class="is-danger width-200"
				type="submit"
				@click="unlockEditPrice"
				v-if="true"
				>Change the Price
			</b-button>
		</div>
		<div v-if="documentModalState.isAddOrEdit === 'add'">
			<b-button
				:disabled="!!isAddDisabled"
				class="is-info margin-top-25 width-200"
				type="submit"
				@click="onAddOrderLine"
				v-if="true"
				>Add Order Item
			</b-button>
		</div>
		<div v-if="documentModalState.isAddOrEdit === 'edit'">
			<b-button
				:disabled="!!isAddDisabled"
				class="is-info margin-top-25 width-200"
				type="submit"
				@click="onUpdateOrderLine"
				v-if="true"
				>Add Order Item & Update Order
			</b-button>
		</div>
	</div>
</template>

<script>
import {
	computed,
	onMounted,
	reactive,
	ref,
	watchEffect,
} from '@vue/composition-api'
import { useQuery, useMutation } from '@vue/apollo-composable'
import numeral from 'numeral'
// ? GraphQL -----------------------------------------------------------------------------------------------------------------------------
import GetItemPricesQuery from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/graphql/GetItemPricesQuery.graphql'
import InsertOrderLineMutation from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/graphql/InsertOrderLineMutation.graphql'
// todo dispatch, Invoice
// ? Composables -------------------------------------------------------------------------------------------------------------------------
import useFormatNumber from '@/_srcv2/composables/format-numbers/useFormatNumber.js'
import useDocumentModalState from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/useDocumentModalState.js'
import useGetUuid from '@/_srcv2/views/_shared/_composables/create-uuid/useGetUuid'
import useDocumentModalSchemaOptions from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/useDocumentModalSchemaOptions.js'
// ? State -------------------------------------------------------------------------------------------------------------------------------
import useOrderHeadersModelSchemaOptions from '@/_srcv2/pages/admin/_shared/useOrderHeadersModelSchemaOptions.js'
import useDraftDocumentLines from '@/_srcv2/pages/admin/create-document-add-lines/draft-document-lines/useDraftDocumentLines.js'

export default {
	name: 'AddDocumentLinesModal',
	setup() {
		const { cleaveToInt100, floatToInt100 } = useFormatNumber()
		const { documentModalState, isAddDisabled } = useDocumentModalState()
		const { modelOrderLines, schemaOrderLines, formOptionsOrderLines } =
			useDocumentModalSchemaOptions()
		// ? --------------------------------------------------------------------------------
		const { modelOrder } = useOrderHeadersModelSchemaOptions()
		const { getUUID } = useGetUuid()
		// ? --------------------------------------------------------------------------------
		onMounted(() => {
			isAddDisabled.value = true
			modelOrderLines.transactionId = getUUID()
			modelOrderLines.transactionType = 'customer order'
			modelOrderLines.stockId = documentModalState.stockId
			modelOrderLines.lineInfo = documentModalState.lineInfo
			modelOrderLines.normalPrice = getRawToFixed2(
				documentModalState.normalPrice,
			)
			modelOrderLines.campaignPrice = documentModalState.campaignPrice
			modelOrderLines.customerSpecialPrice =
				documentModalState.customerSpecialPrice
			modelOrderLines.customerDiscountedPrice =
				documentModalState.customerDiscountedPrice
			modelOrderLines.customerInvoicePrice =
				documentModalState.customerInvoicePrice
			modelOrderLines.customerInvoiceExchangePrice =
				documentModalState.customerInvoiceExchangePrice
			modelOrderLines.vatPercent =
				modelOrder.orderType !== 'Inland' ? 0 : documentModalState.vatPercent
			modelOrderLines.amountCredit = documentModalState.amountCredit
		})
		// ? Get All Prices for stock item
		const options = reactive({
			fetchPolicy: 'network-only',
		})
		const { onResult: onResultPrices, refetch: refetchPrices } = useQuery(
			GetItemPricesQuery,
			() => ({
				company_id: documentModalState.ourCompany,
				customer_id: documentModalState.customerId,
				stock_id: documentModalState.stockId,
			}),
			options,
		)
		// todo ----------------------------------------------------------------------------------------------
		const fetchPrices = (result) => {
			return new Promise((resolve, reject) => {
				if (result !== null || undefined) {
					const customerPrices = {
						rawNormalPrice: result?.stock[0]?.stock_price ?? 0,
						rawCampaignPrice: result?.stock[0]?.campaign_price ?? 0,
						rawSpecialPrice:
							result?.customer_price_list[0]?.customers_price ?? 0,
						aPrice: result?.stock[0]?.stock_price_a ?? 0,
						bPrice: result?.stock[0]?.stock_price_b ?? 0,
						cPrice: result?.stock[0]?.stock_price_c ?? 0,
						dPrice: result?.stock[0]?.stock_price_d ?? 0,
						sPrice: result?.stock[0]?.stock_price_s ?? 0,
						hraPrice: result?.stock[0]?.stock_price_hra ?? 0,
						hrbPrice: result?.stock[0]?.stock_price_hrb ?? 0,
						hrcPrice: result?.stock[0]?.stock_price_hrc ?? 0,
						hrdPrice: result?.stock[0]?.stock_price_hrd ?? 0,
						zPrice: result?.stock[0]?.stock_price_z ?? 0,
						customerPriceClass:
							result?.customers[0]?.customer_price_class ?? 'd',
					}
					resolve(customerPrices)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo -----------------------------------------------------------------------------------------
		const getCustomerDiscountedPrice = (obj) => {
			if (obj.customerPriceClass !== null || undefined) {
				if (obj.customerPriceClass === 'a') {
					return obj.aPrice
				} else if (obj.customerPriceClass === 'b') {
					return obj.bPrice
				} else if (obj.customerPriceClass === 'c') {
					return obj.cPrice
				} else if (obj.customerPriceClass === 'd') {
					return obj.dPrice
				} else if (obj.customerPriceClass === 's') {
					return obj.sPrice
				} else if (obj.customerPriceClass === 'hra') {
					return obj.hraPrice
				} else if (obj.customerPriceClass === 'hrb') {
					return obj.hrbPrice
				} else if (obj.customerPriceClass === 'hrc') {
					return obj.hrcPrice
				} else if (obj.customerPriceClass === 'hrd') {
					return obj.hrdPrice
				} else if (obj.customerPriceClass === 'z') {
					return obj.zPrice
				}
			}
			return 0
		}
		// todo ---------------------------------------------------------------------------------------------
		const getCustomerInvoicePrice = (customerPrices) => {
			if (customerPrices !== null || undefined) {
				const prices = ref([])
				if (customerPrices.rawNormalPrice > 0) {
					prices.value.push(customerPrices.rawNormalPrice)
				}
				if (customerPrices.rawCampaignPrice > 0) {
					prices.value.push(customerPrices.rawCampaignPrice)
				}
				if (customerPrices.rawSpecialPrice > 0) {
					prices.value.push(customerPrices.rawSpecialPrice)
				}
				if (customerPrices.customerPriceClass !== null || undefined) {
					prices.value.push(getCustomerDiscountedPrice(customerPrices))
				}
				return Math.min(...prices.value)
			} else {
				return 0
			}
		}
		// todo ------------------------------------------------------------------------------------
		const excUnit = computed(() => modelOrder.orderExchangeUnit)
		const excRate = computed(() => modelOrder.orderExchangeRate * 10000000000)
		const excRateToFixed10 = computed(() =>
			modelOrder.orderExchangeRate.toFixed(10),
		)
		console.log('%%%%%%%%%%%%%%% exc', excRate, excRate.value)
		console.log('%%%%%%%%%%%%%%% excRateToFixed10', excRateToFixed10.value)
		// ? --------------------------------------------------------------------------------
		const getRawToFixed2 = (number) => {
			return (number / 100).toFixed(2)
		}
		const getFormattedExcPrice = (number) => {
			return ((number * excRate.value) / 10000000000).toFixed(2)
		}
		// todo ------------------------------------------------------------------------------------
		const formatPrices = (customerPrices) => {
			return new Promise((resolve, reject) => {
				if (customerPrices !== null || undefined) {
					const customerFormattedPrices = {
						normalPrice: getFormattedExcPrice(
							getRawToFixed2(customerPrices.rawNormalPrice),
						),
						campaignPrice: getFormattedExcPrice(
							getRawToFixed2(customerPrices.rawCampaignPrice),
						),
						specialPrice: getFormattedExcPrice(
							getRawToFixed2(customerPrices.rawSpecialPrice),
						),
						discountedPrice: getFormattedExcPrice(
							getRawToFixed2(getCustomerDiscountedPrice(customerPrices)),
						),
						invoicePrice: getRawToFixed2(
							getCustomerInvoicePrice(customerPrices),
						),
						invoiceExchangePrice: getRawToFixed2(
							(
								(getCustomerInvoicePrice(customerPrices) * excRate.value) /
								10000000000
							).toFixed(2),
						),
					}
					console.log('***customerFormattedPrices', customerFormattedPrices)
					resolve(customerFormattedPrices)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo ---------------------------------------------------------------------------------------------
		const setPrices = (obj) => {
			console.log('setPrices obj', obj)
			return new Promise((resolve, reject) => {
				if (obj !== null || undefined) {
					modelOrderLines.normalPrice = obj.normalPrice
					modelOrderLines.campaignPrice = obj.campaignPrice
					modelOrderLines.customerSpecialPrice = obj.specialPrice
					modelOrderLines.customerDiscountedPrice = obj.discountedPrice
					modelOrderLines.customerInvoicePrice = obj.invoicePrice
					modelOrderLines.customerInvoiceExchangePrice =
						obj.invoiceExchangePrice
					console.log('setPrices modelOrderLines', modelOrderLines)
					resolve(modelOrderLines)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo ----------------------------------------------------------------------------------------------
		// ? Format values
		numeral.defaultFormat('0,0.00')
		// ? Add the new order line to array
		const getRecord = () => {
			console.log('getRecord modelOrderLines', modelOrderLines)
			return new Promise((resolve) => {
				const record = {
					goods_transaction_id: modelOrderLines.transactionId,
					transaction_type: modelOrderLines.transactionType,
					stock_id: modelOrderLines.stockId,
					line_info: modelOrderLines.lineInfo,
					amount_credit: modelOrderLines.amountCredit,
					order_amount: modelOrderLines.amountCredit,
					dispatch_amount: modelOrderLines.amountCredit,
					unit_price: cleaveToInt100(modelOrderLines.normalPrice),
					vat_percent: modelOrderLines.vatPercent,
					vat_credit: floatToInt100(
						modelOrderLines.customerInvoicePrice *
							(modelOrderLines.vatPercent / 100) *
							modelOrderLines.amountCredit,
					),
					invoice_price: floatToInt100(modelOrderLines.customerInvoicePrice),
					line_price_total_credit: floatToInt100(
						modelOrderLines.customerInvoicePrice * modelOrderLines.amountCredit,
					),
					vat_credit_exchange: floatToInt100(
						removeSpaces(modelOrderLines.customerInvoiceExchangePrice) *
							(modelOrderLines.vatPercent / 100) *
							modelOrderLines.amountCredit,
					),
					invoice_price_exchange: floatToInt100(
						removeSpaces(modelOrderLines.customerInvoiceExchangePrice),
					),
					line_price_total_credit_exchange: floatToInt100(
						removeSpaces(modelOrderLines.customerInvoiceExchangePrice) *
							modelOrderLines.amountCredit,
					),
				}
				console.log('record', record)
				resolve(record)
			})
		}
		// todo -------------------------------------------------------------------------------
		onResultPrices((result) => {
			fetchPrices(result.data).then((customerPrices) => {
				formatPrices(customerPrices).then((customerFormattedPrices) => {
					setPrices(customerFormattedPrices).then((lines) => {
						console.log('lines', lines)
					})
				})
			})
		})
		// ? -----------------------------------------------------------------------------------
		const formValidated = ref(false)

		const onFormValidated = (isValid, errors) => {
			formValidated.value =
				modelOrderLines.amountCredit > 0 &&
				modelOrderLines.customerInvoiceExchangePrice > 0
			if (isValid === true) {
				isAddDisabled.value = false
			} else if (errors.length !== 0) {
				isAddDisabled.value = true
			}
		}
		// todo ----------------------------------------------------------------------------------------------

		// ? Push new order line/
		const { orderLinesArray } = useDraftDocumentLines()
		const onAddOrderLine = () => {
			// ? push to array
			getRecord()
				.then((record) => {
					console.log('getRecord record', record)
					orderLinesArray.value.push(record)
				})
				.then(() => {
					documentModalState.isModalVisible = false
				})
		}
		// todo -------------------------------------------------------------
		const mutationVariable = ref([])
		const { mutate: insertOrderLine } = useMutation(
			InsertOrderLineMutation,
			() => ({
				variables: {
					input: mutationVariable.value,
				},
			}),
		)
		// todo ----------------------------------------------------------------------
		const { reportOrderNumber } = useDraftDocumentLines()
		const getOrderLine = () => {
			console.log('getOrderLine modelOrderLines', modelOrderLines)
			return new Promise((resolve) => {
				const line = {
					goods_transaction_id: modelOrderLines.transactionId,
					order_number: reportOrderNumber.value,
					transaction_type: modelOrderLines.transactionType,
					stock_id: modelOrderLines.stockId,
					line_info: modelOrderLines.lineInfo,
					amount_credit: modelOrderLines.amountCredit,
					order_amount: modelOrderLines.amountCredit,
					dispatch_amount: modelOrderLines.amountCredit,
					unit_price: cleaveToInt100(modelOrderLines.normalPrice),
					vat_percent: modelOrderLines.vatPercent,
					vat_credit: floatToInt100(
						modelOrderLines.customerInvoicePrice *
							(modelOrderLines.vatPercent / 100) *
							modelOrderLines.amountCredit,
					),
					invoice_price: floatToInt100(modelOrderLines.customerInvoicePrice),
					line_price_total_credit: floatToInt100(
						modelOrderLines.customerInvoicePrice * modelOrderLines.amountCredit,
					),
					vat_credit_exchange: floatToInt100(
						removeSpaces(modelOrderLines.customerInvoiceExchangePrice) *
							(modelOrderLines.vatPercent / 100) *
							modelOrderLines.amountCredit,
					),
					invoice_price_exchange: floatToInt100(
						removeSpaces(modelOrderLines.customerInvoiceExchangePrice),
					),
					line_price_total_credit_exchange: floatToInt100(
						removeSpaces(modelOrderLines.customerInvoiceExchangePrice) *
							modelOrderLines.amountCredit,
					),
				}
				resolve(line)
			})
		}
		const onUpdateOrderLine = () => {
			getOrderLine()
				.then((line) => {
					console.log('line', line)
					mutationVariable.value.push(line)
				})
				.then(() => {
					console.log('mutationVariable', mutationVariable)
					insertOrderLine()
					documentModalState.isModalVisible = false
				})
		}
		const resetPrices = () => {
			console.log('refresh is fired')
			refetchPrices()
		}
		const unlockEditPrice = () => {
			let pwd = prompt('Type password to edit the price')
			if (pwd === '1994') {
				console.log('------ modelOrderLines', modelOrderLines)
				console.log('------ schemaOrderLines', schemaOrderLines)
				// schemaOrderLines.groups[0].fields[5].disabled = false
				schemaOrderLines.groups[0].fields[6].disabled = false
			} else
				alert(`Password does not match
	You don't have permission
	to make any change in price
	contact with administrator`)
		}
		// ? ---------------------------------------------------------------------------
		function removeSpaces(str) {
			// Using the replace() method with a regular expression
			return str.replace(/\s/g, '')
		}
		watchEffect(() => {
			if (modelOrderLines.customerInvoiceExchangePrice.length > 0) {
				modelOrderLines.customerInvoicePrice = (
					(parseFloat(
						removeSpaces(modelOrderLines.customerInvoiceExchangePrice),
					) *
						1000000000000) /
					(modelOrder.orderExchangeRate * 10000000000) /
					100
				).toFixed(2)
			}
		})
		// ? ---------------------------------------------------------------------------
		return {
			schemaOrderLines,
			modelOrderLines,
			formOptionsOrderLines,
			onAddOrderLine,
			onFormValidated,
			isAddDisabled,
			documentModalState,
			onUpdateOrderLine,
			excRateToFixed10,
			excUnit,
			excRate,
			resetPrices,
			unlockEditPrice,
		}
	},
}
</script>
<style scoped>
.margin-top-25 {
	margin-top: 25px;
}
.margin-top-15 {
	margin-top: 15px;
}
.exchange-title {
	font-weight: bold;
	font-size: 1.5em;
	color: red;
}
.exchange-unit {
	font-weight: bold;
	font-size: 1.5em;
	color: blue;
	padding-left: 15px;
}
</style>
