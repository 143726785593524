<!-- @format -->

<template>
	<div>
		<div class="margin-bottom-30 margin-top-30">
			<p class="headers-line">
				<span class="tag">Customer:</span>&nbsp;{{ customerDetailsHeadersInfo }}
			</p>
			<p class="headers-line">
				<span class="tag">Customer ID:</span>&nbsp;{{ customerId }}
			</p>
			<p class="headers-line">
				<span class="tag">Org.Number:</span>&nbsp;{{ customerOrgNum }}
			</p>
			<p class="headers-line">
				<span class="tag">Is Risky:</span>&nbsp;{{ isRisky }}
			</p>
			<p class="headers-line">
				<span class="tag">Terms:</span>&nbsp;{{ terms }}
			</p>
		</div>
	</div>
</template>

<script>
import useDocumentHeaderState from '@/_srcv2/pages/admin/_shared/useDocumentHeaderState.js'
import useCheckRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/useCheckRisk.js'

export default {
	name: 'DocumentCustomerInfo',
	setup() {
		const { customerDetailsHeadersInfo } = useDocumentHeaderState()
		const {
			customerIdCR: customerId,
			customerOrgNumberCR: customerOrgNum,
			customerIsRiskyCR: isRisky,
			termsCR: terms,
		} = useCheckRisk()

		return {
			customerDetailsHeadersInfo,
			customerId,
			customerOrgNum,
			isRisky,
			terms,
		}
	},
}
</script>

<style lang="scss" scoped>
.headers-line {
	color: blue;
	font-weight: 700;
	font-size: large;
}
.tag {
	color: black;
	font-size: large;
}
</style>
