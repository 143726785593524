<!-- @format -->

<template>
	<div>
		<!-- only for create return order dispatch -->
		<div
			id="CreateReturnOrderDispatchFrame"
			v-if="showCreateReturnOrderDispatch"
		>
			<div v-if="showEnterDispatchNumber">
				<EnterDispatchNumber :key="refreshKeyEnterDispatchNumber" />
			</div>
			<div v-if="showGetCustomerDispatchByNumber">
				<GetCustomerDispatchByNumber
					:key="refreshKeyGetCustomerDispatchByNumber"
				/>
			</div>
			<div v-if="showTheDispatchToSelectItemToReturn">
				<TheDispatchToSelectItemToReturn
					:key="refreshKeyTheDispatchToSelectItemToReturn"
				/>
			</div>
			<div v-if="isModalVisible">
				<AddReturnDispatchLinesModalFrame :key="keyAddDocumentLinesModal" />
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import EnterDispatchNumber from '@/_srcv2/pages/dispatch/return-dispatch/components/EnterDispatchNumber.vue'
import GetCustomerDispatchByNumber from '@/_srcv2/pages/dispatch/return-dispatch/components/GetCustomerDispatchByNumber.vue'
import TheDispatchToSelectItemToReturn from '@/_srcv2/pages/dispatch/return-dispatch/components/the-dispatch-to-select-item-to-return/TheDispatchToSelectItemToReturn.vue'
import useReturnOrderDispatchState from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchState.js'
import AddReturnDispatchLinesModalFrame from '@/_srcv2/pages/dispatch/return-dispatch/components/the-dispatch-to-select-item-to-return/components/AddReturnDispatchLinesModalFrame.vue'

export default {
	name: 'CreateReturnOrderDispatchFrame',
	components: {
		GetCustomerDispatchByNumber,
		EnterDispatchNumber,
		TheDispatchToSelectItemToReturn,
		AddReturnDispatchLinesModalFrame,
	},
	setup() {
		const {
			showCreateReturnOrderDispatch,
			showEnterDispatchNumber,
			refreshKeyEnterDispatchNumber,
			showGetCustomerDispatchByNumber,
			refreshKeyGetCustomerDispatchByNumber,
			showTheDispatchToSelectItemToReturn,
			refreshKeyTheDispatchToSelectItemToReturn,
			isModalVisible,
			keyAddDocumentLinesModal,
		} = useReturnOrderDispatchState()

		onMounted(() => {
			showEnterDispatchNumber.value = true
			refreshKeyEnterDispatchNumber.value += 1
			showGetCustomerDispatchByNumber.value = false
			showTheDispatchToSelectItemToReturn.value = false
			isModalVisible.value = false
		})
		return {
			showCreateReturnOrderDispatch,
			showEnterDispatchNumber,
			refreshKeyEnterDispatchNumber,
			showGetCustomerDispatchByNumber,
			refreshKeyGetCustomerDispatchByNumber,
			showTheDispatchToSelectItemToReturn,
			refreshKeyTheDispatchToSelectItemToReturn,
			isModalVisible,
			keyAddDocumentLinesModal,
		}
	},
}
</script>

<style lang="scss" scoped></style>
