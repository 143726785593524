<!-- @format -->

<template>
	<div>
		<div>Loading...</div>
	</div>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import GetInvoiceGoodTransactionsQuery from '@/_srcv2/pages/sale-invoice/credit-invoice/graphql/GetInvoiceGoodTransactionsQuery.graphql'
import useCreditInvoiceState from '@/_srcv2/pages/sale-invoice/credit-invoice/scripts/useCreditInvoiceState.js'
export default {
	name: 'GetCustomerInvoiceByNumber',
	setup() {
		const {
			showGetCustomerInvoiceByNumber,
			showTheInvoiceToSelectItemToCredit,
			refreshKeyTheInvoiceToSelectItemToCredit,
			refreshKeyEnterInvoiceNumber,
			// todo ---------------------------------
			companyIdCI,
			customerIdCI,
			invoiceNumberCI,
			// todo ---------------------------------
			customer_nickname,
			customer_title,
			customer_org_num,
			// todo ----------------------------------
			dispatch_address_id,
			invoice_address_id,
			invoice_exchange_rate,
			invoice_exchange_unit,
			invoice_language,
			invoice_type,
			// * -------------------------------------
			paper_invoice_fee,
			is_fee_addable,
			paper_invoice_fee_vat,
			paper_invoice_fee_vat_percent,
			// todo ----------------------------------
			invoiceGoodsTransactionsLines,
			disableInvoice,
		} = useCreditInvoiceState()
		// ? --------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const queryVariables = reactive({
			our_company: companyIdCI.value,
			customer_id: customerIdCI.value,
			invoice_number: invoiceNumberCI.value,
		})
		console.log('queryVariables', queryVariables)
		const { onResult, onError, loading } = useQuery(
			GetInvoiceGoodTransactionsQuery,
			() => queryVariables,
			options,
		)
		// todo ------------------------------------------------------------------
		onError((error) => {
			alert('onError', error)
		})
		// todo ------------------------------------------------------------------
		const finalize = (result) => {
			return new Promise((resolve, reject) => {
				if (result) {
					const d = result.data.document_transactions[0]
					console.log('d', d)
					customer_nickname.value = d.customer.customer_nickname
					customer_title.value = d.customer.customer_title
					customer_org_num.value = d.customer.customer_org_num
					dispatch_address_id.value = d.invoice_address_id
					invoice_address_id.value = d.invoice_address_id
					invoice_exchange_rate.value = d.invoice_exchange_rate
					invoice_exchange_unit.value = d.invoice_exchange_unit
					invoice_language.value = d.invoice_language
					invoice_type.value = d.invoice_type
					paper_invoice_fee.value = d.paper_invoice_fee * -1
					is_fee_addable.value = d.is_fee_addable
					paper_invoice_fee_vat.value = d.paper_invoice_fee_vat * -1
					paper_invoice_fee_vat_percent.value = d.paper_invoice_fee_vat_percent
					invoiceGoodsTransactionsLines.value = d._goods_transactions.map(
						(item) => {
							return {
								goods_transaction_id: item.goods_transaction_id,
								stock_id: item.stock_id,
								line_info: item.line_info,
								unit_price: item.unit_price,
								vat_percent: item.vat_percent,
								invoice_price: item.invoice_price,
								invoice_price_exchange: item.invoice_price_exchange,
								amount_credit: item.amount_credit,
								line_price_total_credit: item.line_price_total_credit,
								vat_credit: item.vat_credit,
								line_price_total_credit_exchange:
									item.line_price_total_credit_exchange,
								vat_credit_exchange: item.vat_credit_exchange,
								invoice_exchange_unit:
									item._document_transactions.invoice_exchange_unit,
							}
						},
					)
					resolve('Data fetched from database')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		onResult((result) => {
			console.log('onResult', result)
			if (result.data?.document_transactions.length === 0) {
				alert('No data found, Check the dispatch number that you have entered!')
				disableInvoice.value = false
				showGetCustomerInvoiceByNumber.value = false
				refreshKeyEnterInvoiceNumber.value += 1
			} else {
				finalize(result).then((message) => {
					alert(message)
					showGetCustomerInvoiceByNumber.value = false
					showTheInvoiceToSelectItemToCredit.value = true
					refreshKeyTheInvoiceToSelectItemToCredit.value += 1
					console.log(
						'**** finalize invoiceGoodsTransactionsLines.value',
						invoiceGoodsTransactionsLines.value,
					)
				})
			}
		})
		return { loading }
	},
}
</script>

<style lang="scss" scoped></style>
