<template>
	<div>
		<div v-if="loading">Loading.....</div>
		<div>Getting Dispatch Report please wait....</div>
	</div>
</template>

<script>
import { useQuery, useResult } from '@vue/apollo-composable'
import { onMounted, reactive } from '@vue/composition-api'
import Store from '@/store'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import GetDispatchReportDataQuery from './GetDispatchReportDataQuery.graphql'

export default {
	name: 'GetDispatchReportData',
	setup() {
		const {
			reportDocumentOurCompany,
			reportDocumentNumber,
			showGetData,
			showPdf,
			keyProcessReport,
		} = useProcessReportStore()
		// get order report info
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result, refetch, loading } = useQuery(
			GetDispatchReportDataQuery,
			() => ({
				company_id: reportDocumentOurCompany.value,
				dispatch_number: reportDocumentNumber.value,
			}),
			options,
		)
		// ****************************************************************************************************
		const getReportData = (data) => {
			return new Promise((resolve, reject) => {
				if (data !== null || undefined) {
					const allReportDataForDispatch = useResult(
						result,
						null,
						(data) => data.dispatch_headers,
					)
					console.log('allReportDataForDispatch', allReportDataForDispatch)
					resolve(allReportDataForDispatch.value[0])
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const storeReportData = (data) => {
			console.log('*-*- report data', data)
			return new Promise((resolve, reject) => {
				if (data !== null || undefined) {
					Store.dispatch('setPdfReportData', data).then(() => {
						if (Store.getters.getPdfReportData !== undefined) {
							console.log('')
							// Router.push({ name: 'OrderReportPdf' })
							showGetData.value = false
							showPdf.value = true
							keyProcessReport.value += 1
							resolve(true)
						}
					})
				} else {
					const reason = new Error('Data can not be stored')
					reject(reason)
				}
			})
		}
		// ----------------------------------------------------------------------------
		const a = () => {
			getReportData(result).then((data) => {
				storeReportData(data)
			})
		}
		console.log('GetDispatchReport created is fired')
		onMounted(() => {
			console.log('GetDispatchReport onMounted is fired')
			refetch().then(() => a())
		})
		return {
			loading,
		}
	},
}
</script>

<style scoped>
.margin-top-25 {
	margin-top: 25px;
}
</style>
