<!-- @format -->

<template>
	<div>
		<div id="addDocumentLines">
			<div class="margin-top-30">
				<SummaryDocumentHeaders />
			</div>
			<div class="margin-top-30" v-if="createLevel === 3">
				<AddOtherDispatchesFrame
					key="keyAddOtherDispatchesFrame"
					v-if="showAddOtherDispatchesFrame"
				/>
			</div>
			<div class="margin-top-30">
				<DraftDocumentLines />
			</div>
			<div>
				<SaveTheDocument />
			</div>

			<div v-if="createLevel !== 4">
				<GetStockItems v-if="showGetStockItems" />
			</div>

			<div v-if="createLevel !== 4 && showGetCustomerConsumptions === true">
				<div>
					<b-button
						type="is-success"
						style="margin-top: 30px; width: 180px"
						@click="showGetCustomerConsumptionsInnerTable = true"
						>Show Consumption</b-button
					>
				</div>
				<GetCustomerConsumptions v-if="showGetCustomerConsumptionsInnerTable" />
			</div>
		</div>
		<div class="max-width-150">
			<AddDocumentLinesModalFrame
				v-if="isModalVisible"
				@close="closeModal"
				:key="keyModal"
			/>
		</div>
	</div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
// ? Components -------------------------------------------------------------------------------------------------------------------------------------------
import SummaryDocumentHeaders from '@/_srcv2/pages/admin/create-document-add-lines/_summary-document-headers/SummaryDocumentHeaders.vue'
import AddOtherDispatchesFrame from '@/_srcv2/pages/admin/create-order-dispatch-invoice/AddOtherDispatchesFrame.vue'
import DraftDocumentLines from '@/_srcv2/pages/admin/create-document-add-lines/draft-document-lines/DraftDocumentLines.vue'
import SaveTheDocument from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/SaveTheDocument.vue'
import GetCustomerConsumptions from '@/_srcv2/pages/admin/create-document-add-lines/get-customer-consumptions/GetCustomerConsumptions.vue'
import GetStockItems from '@/_srcv2/pages/admin/create-document-add-lines/get-stock-items/GetStockItems.vue'
import AddDocumentLinesModalFrame from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/AddDocumentLinesModalFrame.vue'
// ? Composables ------------------------------------------------------------------------------------------------------------------------------------------
import useDocumentModalState from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/useDocumentModalState.js'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
import useSalesInvoiceState from '@/_srcv2/pages/sale-invoice/_shared/useSalesInvoiceState.js'

export default {
	name: 'AddDocumentLines',
	components: {
		SummaryDocumentHeaders,
		AddOtherDispatchesFrame,
		DraftDocumentLines,
		GetStockItems,
		GetCustomerConsumptions,
		SaveTheDocument,
		AddDocumentLinesModalFrame,
	},
	setup() {
		const {
			showGetSelectedDispatchesList,
			keyAddOtherDispatchesFrame,
			showAddOtherDispatchesFrame,
			showGetStockItems,
			showGetCustomerConsumptionsInnerTable,
			showGetCustomerConsumptions,
		} = useSalesInvoiceState()
		const { showAddGoodsTransactions, createLevel } =
			useCreateDocumentComponentState()
		const { isModalVisible, keyModal } = useDocumentModalState()
		const closeModal = () => (isModalVisible.value = false)
		const show = ref(false)
		showAddOtherDispatchesFrame.value = false
		showGetCustomerConsumptions.value = false
		showGetStockItems.value = false
		const setShowStatus = () => {
			if (createLevel.value === 3) {
				showAddOtherDispatchesFrame.value = true
				showGetCustomerConsumptions.value = false
				showGetStockItems.value = false
			} else if (createLevel.value === 2) {
				showGetStockItems.value = true
				showAddOtherDispatchesFrame.value = false
				showGetCustomerConsumptions.value = false
			} else if (createLevel.value === 1) {
				showGetStockItems.value = true
				showAddOtherDispatchesFrame.value = false
				showGetCustomerConsumptions.value = false
			}
		}
		onMounted(() => {
			console.log('AddDocumentLines is initialized')
			keyAddOtherDispatchesFrame.value += 1
			showGetSelectedDispatchesList.value = true
			showGetCustomerConsumptionsInnerTable.value = false
			setShowStatus()
		})
		return {
			showGetSelectedDispatchesList,
			keyAddOtherDispatchesFrame,
			showAddGoodsTransactions,
			isModalVisible,
			keyModal,
			closeModal,
			show,
			createLevel,
			showAddOtherDispatchesFrame,
			showGetStockItems,
			showGetCustomerConsumptionsInnerTable,
			showGetCustomerConsumptions,
		}
	},
}
</script>

<style lang="scss" scoped></style>
