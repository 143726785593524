<!-- @format -->

<template>
	<div class="info">
		<div>
			<img src="@/assets/loadinggif.gif" />
		</div>
		<div>Getting Eligible Dispatches list...</div>
	</div>
</template>

<script>
import { reactive } from '@vue/composition-api'
import useSalesInvoiceState from '@/_srcv2/pages/sale-invoice/_shared/useSalesInvoiceState'
import GetDispatchesToInvoiceToSameInvoiceAddressQuery from '@/_srcv2/pages/sale-invoice/graphql/GetDispatchesToInvoiceToSameInvoiceAddressQuery.gql'
import { useQuery } from '@vue/apollo-composable'

export default {
	name: 'GetSelectedDispatchesList',
	setup() {
		const {
			ourCompany,
			customer,
			invoiceAddress,
			dispatches,
			variablesQ1,
			showGetSelectedDispatchesList,
			showGetStockItems,
		} = useSalesInvoiceState()

		console.log('variablesQ1', variablesQ1.value)
		const optionsQ1 = reactive({
			fetchPolicy: 'network-only',
		})
		const { onError, onResult } = useQuery(
			GetDispatchesToInvoiceToSameInvoiceAddressQuery,
			() => variablesQ1.value,
			optionsQ1,
		)
		onError((error) => {
			console.log('************************ error', error)
		})
		const setFormFieldsValues = (item) => {
			console.log('--------------------> List item', item)
			ourCompany.value = item.companies[0].company_nickname
			customer.value = item.customers[0]
			invoiceAddress.value = item.addresses[0]
			dispatches.value = item.dispatch_headers.map((item) => {
				return {
					dispatch_date: item.dispatch_date,
					dispatch_type: item.dispatch_type,
					dispatch_exchange_rate: item.dispatch_exchange_rate,
					dispatch_exchange_unit: item.dispatch_exchange_unit,
					dispatch_headers_addresses_rel_dispatch_address:
						item.dispatch_headers_addresses_rel_dispatch_address,
					_goods_transactions: item._goods_transactions,
					dispatch_lock: item.dispatch_lock,
					dispatch_number: item.dispatch_number,
					dispatch_total:
						item._goods_transactions_aggregate.aggregate.sum
							.line_price_total_credit +
						item._goods_transactions_aggregate.aggregate.sum.vat_credit,
				}
			})
		}
		onResult((result) => {
			console.log(
				'******************* GetSelectedDispatchesList result',
				result,
			)
			showGetStockItems.value = true
			setFormFieldsValues(result.data)
			showGetSelectedDispatchesList.value = false
		})

		return {}
	},
}
</script>

<style lang="css" scoped>
.info {
	margin-top: 30px;
	margin-bottom: 30px;
	font-weight: 600;
	font-size: larger;
	color: blue;
}
</style>
