var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background-color":"#4c5c7a","padding":"20px","color":"white","padding-top":"30px"}},[_vm._m(0),_c('div',[_c('div',[(_vm.isPopupVisible)?_c('div',[_c('VPopup',{attrs:{"closePopup":_vm.onClosePopup},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.formName)+" ")])]},proxy:true},{key:"body",fn:function(){return [_c('div',[_c('ViewSelectedDispatch')],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.formName)+" ")])]},proxy:true}],null,false,4173953959)})],1):_vm._e()]),_c('p',{staticClass:"text-bold"},[_vm._v("Customer's dispatches waiting to invoice")]),_c('div',{staticClass:"margin-bottom-30 margin-top-10"},[_c('vue-good-table',{attrs:{"id":"dispatches","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","max-height":"700px","fixed-header":true,"pagination-options":{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [5, 10, 15, 20],
					dropdownAllowAll: false,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page',
					allLabel: 'All',
				}},on:{"on-row-dblclick":_vm.onRowDoubleClick,"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'select')?_c('span',[_c('b-button',{staticClass:"is-small is-success",attrs:{"disabled":_vm.isSelectDisabled}},[_vm._v(" Select ")])],1):(props.column.field === 'dispatch_total')?_c('span',[_c('p',{staticClass:"text-bold text-purple text-align-right"},[_vm._v(" "+_vm._s(_vm.numeral(props.row.dispatch_total / 100).format('0,0.00'))+" ")])]):(props.column.field === 'preview')?_c('span',[_c('b-button',{staticClass:"is-small is-info"},[_vm._v("Preview")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('p',{staticClass:"text-bold"},[_vm._v("Selected dispatches to invoice")]),_c('div',{staticClass:"margin-top-10 margin-bottom-30"},[_c('vue-good-table',{attrs:{"id":"selected_dispatches","columns":_vm.selectedDispatchesColumns,"rows":_vm.rowsSelectedDispatches,"styleClass":"vgt-table striped bordered","theme":"black-rhino","max-height":"700px","fixed-header":true,"pagination-options":{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [5, 10, 15, 20],
					dropdownAllowAll: false,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page',
					allLabel: 'All',
				}},on:{"on-row-dblclick":_vm.onRowDoubleClickSelectedDispatches,"on-cell-click":_vm.onCellClickSelectedDispatches},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'remove')?_c('span',[_c('b-button',{staticClass:"is-small is-success",attrs:{"disabled":_vm.isRemoveDisabled}},[_vm._v(" Remove ")])],1):(props.column.field === 'dispatch_total')?_c('span',[_c('p',{staticClass:"text-bold text-purple text-align-right"},[_vm._v(" "+_vm._s(_vm.numeral(props.row.dispatch_total / 100).format('0,0.00'))+" ")])]):(props.column.field === 'preview')?_c('span',[_c('b-button',{staticClass:"is-small is-info"},[_vm._v("Preview")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"font-size":"xx-large","margin-bottom":"20px"}},[_vm._v(" Add Old Dispatches to Current Invoice ")])])}]

export { render, staticRenderFns }