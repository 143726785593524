/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive } from '@vue/composition-api'

import useDocumentModalState from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/useDocumentModalState.js'
Vue.use(VueCompositionApi)

const { isAddDisabled } = useDocumentModalState()

const lineState = reactive({
	amount_creditRD: 0,
	vat_creditRD: 0,
	vat_credit_exchangeRD: 0,
	line_price_total_creditRD: 0,
	line_price_total_credit_exchangeRD: 0,
})

const getTotal = (total, amount, returnAmount) => {
	console.log('amount', amount)
	console.log('returnAmount', returnAmount)
	console.log('typeof amount', typeof amount)
	console.log('typeof returnAmount', typeof returnAmount)
	console.log('typeof total', typeof total)
	const returnValue = Math.round(
		((returnAmount / amount) * 1000000 * total) / 1000000,
	)
	// eslint-disable-next-line no-compare-neg-zero
	return returnValue == -0 ? 0 : returnValue
}

const modelOrderLines = reactive({
	amount_credit: '',
	dispatch_amount: '',
	order_amount: '',
	goods_transaction_id: '',
	invoice_price: '',
	invoice_price_exchange: '',
	invoice_price_exchange_cleave: '',
	line_info: '',
	line_price_total_credit: '',
	line_price_total_credit_exchange: '',
	stock_id: '',
	transaction_type: '',
	unit_price: '',
	vat_credit: '',
	vat_credit_exchange: '',
	vat_percent: '',
})
const schemaOrderLines = reactive({
	groups: [
		{
			legend: 'Stock Item to add to Order',
			id: 'orderLines',
			featured: false,
			fields: [
				{
					type: 'input',
					inputType: 'text',
					label:
						'Order info (stock id - item name - unit) You can edit if you need',
					model: 'line_info',
					placeholder: 'Please enter Information',
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (errors) {
						if (errors.length > 0) {
							console.log(
								'Validation error in Information field! Errors:',
								errors,
							)
						}
					},
				},
				// todo -----------------------------------------------------------------------------
				{
					type: 'cleave',
					label: `Item Invoice Exchange Price`,
					model: 'invoice_price_exchange_cleave',
					placeholder: 'Please get customer sale-invoice exchange price',
					featured: true,
					disabled: true,
					readonly: false,
					required: true,
					validator: ['notnegativenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							isAddDisabled.value = true
							console.log('Validation error in Price field! Errors:', errors)
						}
					},
				},
				// todo ------------------------------------------------------------------------------
				{
					type: 'input',
					inputType: 'number',
					label: 'Order Amount',
					model: 'amount_credit',
					placeholder: 'Please enter Order Amount to return',
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: [
						'required',
						'negativenumber',
						'integer',
						'notnull',
						'notempty',
					],
					onValidated: function (model, errors) {
						// console.log('*** validated', model)
						if (errors.length > 0) {
							isAddDisabled.value = true
							console.log(
								'Validation error in Information field! Errors:',
								errors,
							)
						} else {
							console.log('model.amount_credit', model.amount_credit)
							console.log(
								'lineState.line_price_total_creditRD',
								lineState.line_price_total_creditRD,
							)
							console.log(
								'lineState.amount_creditRD',
								lineState.amount_creditRD,
							)
							console.log('model.amount_credit', model.amount_credit)
							console.log('model.amount_credit', model.amount_credit)
							model.dispatch_amount = model.amount_credit
							model.order_amount = model.amount_credit
							model.line_price_total_credit = getTotal(
								lineState.line_price_total_creditRD,
								lineState.amount_creditRD,
								model.amount_credit,
							)
							model.line_price_total_credit_exchange = getTotal(
								lineState.line_price_total_credit_exchangeRD,
								lineState.amount_creditRD,
								model.amount_credit,
							)
							model.vat_credit = getTotal(
								lineState.vat_creditRD,
								lineState.amount_creditRD,
								model.amount_credit,
							)
							model.vat_credit_exchange = getTotal(
								lineState.vat_credit_exchangeRD,
								lineState.amount_creditRD,
								model.amount_credit,
							)
						}
					},
				},
			],
		},
	],
})
const formOptionsOrderLines = reactive({
	validateAfterLoad: false,
	validateAfterChanged: true,
	validateAsync: true,
	fieldIdPrefix: 'orderLines',
})
export default function useReturnDispatchModalSchemaOptions() {
	return {
		modelOrderLines,
		schemaOrderLines,
		formOptionsOrderLines,
		lineState,
		// ...toRefs(modelOrderLines),
	}
}
