/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'

Vue.use(VueCompositionApi)
// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'

const orderHeaderState = reactive({
	// ? --------------------------------
	isDatePickerDisabled: false,
	// ? --------------------------------
	orderIssueDate: '',
	// ? --------------------------------
	customerAddressArray: [],
	customerCountryArray: [],
	showGetDocumentHeadersDataComponent: true,
	showInfoAndHeaders: false,
	keySelectOrderHeaders: 0,
	keyDocumentCustomerInfo: 0,
	// ? --------------------------------
	customerDetailsHeadersInfo: '',
	documentCustomerEmail: '',
})

export default function useDocumentHeaderState() {
	return {
		...toRefs(orderHeaderState),
		orderHeaderState,
	}
}
