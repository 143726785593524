<!-- @format -->

<template>
	<div>
		<div>Loading...</div>
	</div>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import GetDispatchGoodTransactionsQuery from '@/_srcv2/pages/dispatch/return-dispatch/GetDispatchGoodTransactionsQuery.graphql'
import useReturnOrderDispatchState from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchState.js'

export default {
	name: 'GetCustomerInvoiceByNumber',
	setup() {
		const {
			showGetCustomerDispatchByNumber,
			showTheDispatchToSelectItemToReturn,
			refreshKeyTheDispatchToSelectItemToReturn,
			refreshKeyEnterDispatchNumber,
			// todo ---------------------------------
			companyIdRD,
			customerIdRD,
			dispatchNumberRD,
			// todo ---------------------------------
			customerRD,
			customer_nickname,
			customer_title,
			customer_org_num,
			// todo ----------------------------------
			dispatch_address_id,
			invoice_address_id,
			dispatch_exchange_rate,
			dispatch_exchange_unit,
			dispatch_language,
			dispatch_type,
			// todo ----------------------------------
			dispatchGoodsTransactionsLines,
			disableDispatch,
		} = useReturnOrderDispatchState()
		// ? --------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const queryVariables = reactive({
			company_id: companyIdRD.value,
			customer_id: customerIdRD.value,
			dispatch_number: dispatchNumberRD.value,
		})
		const { onResult, onError } = useQuery(
			GetDispatchGoodTransactionsQuery,
			() => queryVariables,
			options,
		)
		// todo ------------------------------------------------------------------
		onError((error) => {
			alert('onError', error)
		})
		// todo ------------------------------------------------------------------
		const finalize = (result) => {
			return new Promise((resolve, reject) => {
				if (result) {
					const d = result.data.dispatch_headers[0]
					customer_nickname.value = d.customer.customer_nickname
					customer_title.value = d.customer.customer_title
					customer_org_num.value = d.customer.customer_org_num
					customerRD.value = `${d.customer.customer_title} - ${d.customer.customer_nickname} ${d.customer.customer_org_num} - ${d.customer.customer_id}`
					dispatch_address_id.value = d.dispatch_address_id
					invoice_address_id.value = d.invoice_address_id
					dispatch_exchange_rate.value = d.dispatch_exchange_rate
					dispatch_exchange_unit.value = d.dispatch_exchange_unit
					dispatch_language.value = d.dispatch_language
					dispatch_type.value = d.dispatch_type
					dispatchGoodsTransactionsLines.value = d._goods_transactions.map(
						(item) => {
							return {
								stock_id: item.stock_id,
								line_info: item.line_info,
								unit_price: item.unit_price,
								vat_percent: item.vat_percent,
								invoice_price: item.invoice_price,
								invoice_price_exchange: item.invoice_price_exchange,
								amount_credit: item.amount_credit,
								line_price_total_credit: item.line_price_total_credit,
								vat_credit: item.vat_credit,
								line_price_total_credit_exchange:
									item.line_price_total_credit_exchange,
								vat_credit_exchange: item.vat_credit_exchange,
								dispatch_exchange_unit:
									item._dispatch_headers.dispatch_exchange_unit,
							}
						},
					)
					resolve('Data fetched from database')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		onResult((result) => {
			console.log('onResult', result)
			if (result.data?.dispatch_headers.length === 0) {
				alert('No data found, Check the dispatch number that you have entered!')
				disableDispatch.value = false
				showGetCustomerDispatchByNumber.value = false
				refreshKeyEnterDispatchNumber.value += 1
			} else {
				finalize(result).then((message) => {
					alert(message)
					showGetCustomerDispatchByNumber.value = false
					showTheDispatchToSelectItemToReturn.value = true
					refreshKeyTheDispatchToSelectItemToReturn.value += 1
				})
			}
		})
	},
}
</script>

<style lang="scss" scoped></style>
