<!-- @format -->

<template>
	<div>
		<div v-if="showCheckCustomerRisk">
			<CheckCustomerRisk :key="refreshKeyCheckCustomerRisk" />
		</div>
		<div v-if="showDecideComponent">
			<DecideComponent :key="refreshKeyDecideComponent" />
		</div>
		<div v-if="showModalInfoUnpaidRemindedInvoices">
			<ModalInfoUnpaidRemindedInvoices
				:key="refreshKeyModalInfoUnpaidRemindedInvoices"
			/>
		</div>
	</div>
</template>

<script>
import CheckCustomerRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/CheckCustomerRisk.vue'
import ModalInfoUnpaidRemindedInvoices from '@/_srcv2/pages/_utilities/_check-risk/info-unpaid-invoices/ModalInfoUnpaidRemindedInvoices.vue'
import DecideComponent from '@/_srcv2/pages/_utilities/_check-risk/decide-component/DecideComponent.vue'
import useCheckRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/useCheckRisk.js'
import { onMounted, onUnmounted } from '@vue/composition-api'

export default {
	name: 'CheckRiskModule',
	components: {
		CheckCustomerRisk,
		DecideComponent,
		ModalInfoUnpaidRemindedInvoices,
	},
	setup() {
		console.log('CheckRiskModule initialized from checkRiskModule line 34')
		const {
			showCheckCustomerRisk,
			showDecideComponent,
			showModalInfoUnpaidRemindedInvoices,
			refreshKeyCheckCustomerRisk,
			refreshKeyDecideComponent,
			refreshKeyModalInfoUnpaidRemindedInvoices,
		} = useCheckRisk()

		onMounted(() => {
			console.log('CheckRiskModule is mounted')
			showCheckCustomerRisk.value = true
			refreshKeyCheckCustomerRisk.value += 1
		})

		onUnmounted(() => {
			console.log('CheckRiskModule is unmounted')
			showCheckCustomerRisk.value = false
		})

		return {
			showCheckCustomerRisk,
			showDecideComponent,
			showModalInfoUnpaidRemindedInvoices,
			refreshKeyCheckCustomerRisk,
			refreshKeyDecideComponent,
			refreshKeyModalInfoUnpaidRemindedInvoices,
		}
	},
}
</script>

<style lang="scss" scoped></style>
