<!-- @format -->

<template>
	<div>
		<form id="form" class="margin-top-15">
			<span class="exchange-title">All the prices are in</span>
			<span class="exchange-unit">{{ excUnit }}</span
			><br />
			<span class="exchange-title">1 kr.=</span>
			<span class="exchange-unit"
				>{{ excRateToFixed10 }} &nbsp; {{ excUnit }}</span
			>
			<vue-form-generator
				:schema="schemaOrderLines"
				:model="modelOrderLines"
				:options="formOptionsOrderLines"
				@validated="onFormValidated"
			>
			</vue-form-generator>
		</form>
		<div style="display: flex; justify-content: space-between">
			<b-button
				:disabled="isAddDisabled"
				class="is-success margin-top-25 width-200"
				type="submit"
				@click="onEnterReturnAmount"
				v-if="true"
				>Add Order Item
			</b-button>
			<b-button
				class="is-danger margin-top-25 width-200"
				type="submit"
				@click="onCancelAll"
				v-if="true"
				>Cancel
			</b-button>
		</div>
	</div>
</template>

<script>
import { computed, onMounted } from '@vue/composition-api'
// todo dispatch, Invoice
// ? Composables -------------------------------------------------------------------------------------------------------------------------
import useReturnOrderDispatchState from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchState.js'
import useReturnDispatchModalSchemaOptions from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchModalSchemaOptions.js'

export default {
	name: 'AddReturnDispatchLinesModal',
	setup() {
		const {
			returnOrderDispatchState,
			isAddDisabled,
			returnDispatchGoodsTransactionsLines,
		} = useReturnOrderDispatchState()
		const { modelOrderLines, schemaOrderLines, formOptionsOrderLines } =
			useReturnDispatchModalSchemaOptions()
		// todo ------------------------------------------------------------------------------------
		const excUnit = computed(
			() => returnOrderDispatchState.dispatch_exchange_unit,
		)
		const excRate = computed(
			() => returnOrderDispatchState.dispatch_exchange_rate * 10000000000,
		)
		const excRateToFixed10 = computed(() =>
			returnOrderDispatchState.dispatch_exchange_rate.toFixed(10),
		)
		// todo ----------------------------------------------------------------------------------------------
		onMounted(() => {
			isAddDisabled.value = true
		})
		// todo ----------------------------------------------------------------------------------------------

		const onFormValidated = (isValid, errors) => {
			if (isValid === true) {
				setTimeout(() => {
					isAddDisabled.value = false
				}, 500)
			} else if (errors.length !== 0) {
				isAddDisabled.value = true
			}
		}
		// todo ----------------------------------------------------------------------------------------------
		const condition = true
		const getLineData = () => {
			return new Promise((resolve, reject) => {
				if (condition) {
					console.log(
						'returnDispatchGoodsTransactionsLines.value before',
						returnDispatchGoodsTransactionsLines.value,
					)
					console.log('onEnterReturnAmount modelOrderLines', modelOrderLines)
					const line = {
						amount_credit: modelOrderLines.amount_credit,
						dispatch_amount: modelOrderLines.dispatch_amount,
						order_amount: modelOrderLines.order_amount,
						goods_transaction_id: modelOrderLines.goods_transaction_id,
						invoice_price: modelOrderLines.invoice_price,
						invoice_price_exchange: modelOrderLines.invoice_price_exchange,
						line_info: modelOrderLines.line_info,
						line_price_total_credit: modelOrderLines.line_price_total_credit,
						line_price_total_credit_exchange:
							modelOrderLines.line_price_total_credit_exchange,
						stock_id: modelOrderLines.stock_id,
						transaction_type: modelOrderLines.transaction_type,
						unit_price: modelOrderLines.unit_price,
						vat_credit: modelOrderLines.vat_credit,
						vat_credit_exchange: modelOrderLines.vat_credit_exchange,
						vat_percent: modelOrderLines.vat_percent,
					}
					console.log('line before', line)
					resolve(line)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const onEnterReturnAmount = () => {
			getLineData()
				.then((line) => {
					returnDispatchGoodsTransactionsLines.value.push(line)
					console.log(
						'returnDispatchGoodsTransactionsLines.value after',
						returnDispatchGoodsTransactionsLines.value,
					)
				})
				.then(() => {
					returnOrderDispatchState.isModalVisible = false
				})
		}
		const onCancelAll = () => {
			returnOrderDispatchState.isModalVisible = false
		}
		return {
			schemaOrderLines,
			modelOrderLines,
			formOptionsOrderLines,
			onEnterReturnAmount,
			onCancelAll,
			onFormValidated,
			isAddDisabled,
			excRateToFixed10,
			excUnit,
			excRate,
		}
	},
}
</script>
<style scoped>
.margin-top-25 {
	margin-top: 25px;
}
.margin-top-15 {
	margin-top: 15px;
}
.exchange-title {
	font-weight: bold;
	font-size: 1.5em;
	color: red;
}
.exchange-unit {
	font-weight: bold;
	font-size: 1.5em;
	color: blue;
	padding-left: 15px;
}
</style>
