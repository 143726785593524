<!-- @format -->

<template>
	<div>
		<HeroBox :title="title" :subtitle="subtitle" :color="color" />
	</div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'

export default {
	name: 'CreateDocumentHeroBox',
	components: { HeroBox },
	setup() {
		const title = ref('')
		const subtitle = ref('')
		const color = ref('')
		// ? ----------------------------------------------
		const { createLevel } = useCreateDocumentComponentState()
		onMounted(() => {
			switch (createLevel.value) {
				case 1:
					title.value = 'Create Order'
					subtitle.value = 'Please Select a company to create a new order'
					color.value = 'is-danger'
					break
				case 2:
					title.value = 'Create Order & Dispatch'
					subtitle.value =
						'Please Select a company to create both order & dispatch'
					color.value = 'is-info'
					break
				case 3:
					title.value = 'Create Order & Dispatch & Invoice'
					subtitle.value =
						'Please Select a company to create all (order & dispatch & invoice)'
					color.value = 'is-success'
					break
				case 4:
					title.value = 'Create Credit Invoice Dispatch Order'
					subtitle.value = 'Please Select a company to create credit invoice'
					color.value = 'is-danger'
					break
				case 0:
					title.value = 'Create Return Order & Dispatch'
					subtitle.value =
						'Please Select a company to create return order & dispatch'
					color.value = 'is-success'
					break
				default:
					title.value = 'Create Order'
					subtitle.value = 'Please Select a company to create a new order'
					color.value = 'is-danger'
			}
		})
		// ? -----------------------------------------------
		return {
			title,
			subtitle,
			color,
		}
	},
}
</script>

<style lang="scss" scoped></style>
