/** @format */

// Format numbers to use in reports and forms
import numeral from 'numeral'

export default function useReportNumber() {
	// todo Format values
	numeral.defaultFormat('0,0.00')
	// Format numbers to/from form fields
	// todo Format numbers from Store to database
	const floatToInt100 = (float) => {
		return Math.round(float * 100)
	}
	const cleaveToInt100 = (str) => {
		console.log('cleaveToInt100 str', typeof str)
		const convertedString = typeof str === 'string' ? str : String(str)
		console.log('cleaveToInt100 convertedString', typeof convertedString)
		const checkedStr = convertedString.length > 0 ? convertedString : '0.00'
		console.log('cleaveToInt100 checkedStr', typeof checkedStr)
		return Math.round(parseFloat(checkedStr) * 100)
	}
	// todo From float number to cleave form field
	const floatToCleave = (float) => {
		return String(Math.round(float * 100) / 100)
	}
	// From database to form
	const intToFloatCleave = (number) => {
		return String((parseFloat(number) / 100).toFixed(2))
	}
	// todo ----------------------------------------------------------------------------------
	return {
		// intToFloatReport,
		intToFloatCleave,
		// cleaveToFloat,
		floatToCleave,
		cleaveToInt100,
		floatToInt100,
		// intToFloatWithoutCurrency,
	}
}
