<!-- @format -->

<template>
	<div>
		<div>
			<div class="margin-top-30">
				<ReturnDispatchLines />
			</div>
			<div>
				<SaveTheReturnDispatch />
			</div>
			<div class="margin-top-30">
				<DispatchToSelectItemFrom />
			</div>
		</div>
	</div>
</template>

<script>
import SaveTheReturnDispatch from '@/_srcv2/pages/dispatch/return-dispatch/components/the-dispatch-to-select-item-to-return/components/SaveTheReturnDispatch.vue'
import DispatchToSelectItemFrom from '@/_srcv2/pages/dispatch/return-dispatch/components/the-dispatch-to-select-item-to-return/components/DispatchToSelectItemFrom.vue'
import ReturnDispatchLines from '@/_srcv2/pages/dispatch/return-dispatch/components/the-dispatch-to-select-item-to-return/components/ReturnDispatchLines.vue'

export default {
	name: 'TheDispatchToSelectItemToReturn',
	components: {
		DispatchToSelectItemFrom,
		SaveTheReturnDispatch,
		ReturnDispatchLines,
	},
}
</script>

<style lang="scss" scoped></style>
