<!-- @format -->

<template>
	<div>
		<div
			class="text-bold"
			v-if="$store.getters.getShowSubSummaryHeaders"
			:key="$store.getters.getKeySubSummaryHeaders"
		>
			Order Headers Summary
		</div>
		<div>{{ customerDetailsHeadersInfo }}</div>
		<div>{{ customerInfo }}</div>
		<div>{{ orderDate }}</div>
	</div>
</template>

<script>
import { computed, reactive } from '@vue/composition-api'
import useCheckRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/useCheckRisk.js'
import useDocumentHeaderState from '@/_srcv2/pages/admin/_shared/useDocumentHeaderState.js'

export default {
	name: 'SummaryDocumentHeaders',
	setup() {
		const { customerIsRiskyCR: isRisky, termsCR: terms } = useCheckRisk()
		const { orderIssueDate, customerDetailsHeadersInfo } =
			useDocumentHeaderState()
		return reactive({
			customerDetailsHeadersInfo,
			customerInfo: computed(
				() =>
					`Customer's Situation: ${terms.value} - ${
						isRisky.value ? 'Risky' : 'Not Risky'
					}`,
			),
			orderDate: computed(() => `Order Date: ${orderIssueDate.value}`),
		})
	},
}
</script>

<style scoped></style>
