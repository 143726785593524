<!-- @format -->

<template>
	<div>
		<SubNavbar page="Admin" v-if="navbarType === 'admin'" />
		<SubNavbar page="Order" v-if="navbarType === 'order'" />
		<SubNavbar page="Sales Invoice" v-if="navbarType === 'SaleInvoice'" />
		<SubNavbar
			page="Dispatch"
			v-if="navbarType === 'dispatch' || navbarType === 'returnDispatch'"
		/>
		<CreateDocumentHeroBox />
		<div v-if="showSearchActiveCustomers">
			<SearchActiveCustomers />
		</div>
		<div class="margin-top-30" v-if="showGetDocumentHeadersDataComponent">
			<GetDocumentHeadersDataComponent
				:key="refreshKeyGetDocumentHeadersDataComponent"
			/>
		</div>
		<div v-if="showCreateReturnOrderDispatch">
			<CreateReturnOrderDispatchFrame
				:key="refreshKeyCreateReturnOrderDispatch"
			/>
		</div>
		<div v-if="showCreateCreditInvoice">
			<CreateCreditInvoiceDispatchOrderFrame
				:key="refreshKeyCreateCreditInvoice"
			/>
		</div>
		<div v-if="showDocumentHeadersGroup">
			<DocumentHeadersGroup :key="refreshKeyDocumentHeadersGroup" />
		</div>
		<div v-if="showAddGoodsTransactions">
			<AddDocumentLines :key="refreshKeyAddGoodsTransactions" />
		</div>
	</div>
</template>

<script>
import { onMounted, computed } from '@vue/composition-api'

// ? components-----------------------------------------------------------------------------------
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import CreateDocumentHeroBox from '@/_srcv2/pages/admin/create-document/components/CreateDocumentHeroBox.vue'
import SearchActiveCustomers from '@/_srcv2/pages/admin/_shared/SearchActiveCustomers.vue'
import GetDocumentHeadersDataComponent from '@/_srcv2/pages/admin/_shared/GetDocumentHeadersDataComponent.vue'
import DocumentHeadersGroup from '@/_srcv2/pages/admin/_shared/DocumentHeadersGroup.vue'
import AddDocumentLines from '@/_srcv2/pages/admin/create-document-add-lines/AddDocumentLines.vue'
import CreateReturnOrderDispatchFrame from '@/_srcv2/pages/dispatch/return-dispatch/components/CreateReturnOrderDispatchFrame.vue'
import CreateCreditInvoiceDispatchOrderFrame from '@/_srcv2/pages/sale-invoice/credit-invoice/components/CreateCreditInvoiceDispatchOrderFrame.vue'

// ? Composables --------------------------------------------------------------------------------
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
import useReturnOrderDispatchState from '@/_srcv2/pages/dispatch/return-dispatch/useReturnDispatchState.js'
import useCreditInvoiceState from '@/_srcv2/pages/sale-invoice/credit-invoice/scripts/useCreditInvoiceState.js'

export default {
	name: 'CreateDocument',
	components: {
		SubNavbar,
		CreateDocumentHeroBox,
		SearchActiveCustomers,
		CreateReturnOrderDispatchFrame,
		CreateCreditInvoiceDispatchOrderFrame,
		GetDocumentHeadersDataComponent,
		DocumentHeadersGroup,
		AddDocumentLines,
	},
	setup(_, context) {
		const routeName = context.root.$route.name
		const navbarType = computed(() => {
			switch (routeName) {
				case 'AddOrder':
					return 'order'
				case 'CreateOrder':
					return 'admin'
				case 'CreateOrderDispatch':
					return 'admin'
				case 'CreateOrderDispatchInvoice':
					return 'admin'
				case 'CreateReturnOrderDispatch':
					return 'returnDispatch'
				case 'CreateCreditInvoiceDispatchOrder':
					return 'SaleInvoice'
				default:
					return 'order'
			}
		})
		const {
			showSearchActiveCustomers,
			showGetDocumentHeadersDataComponent,
			refreshKeyGetDocumentHeadersDataComponent,
			showDocumentHeadersGroup,
			refreshKeyDocumentHeadersGroup,
			showAddGoodsTransactions,
			refreshKeyAddGoodsTransactions,
		} = useCreateDocumentComponentState()
		// ? -----------------------------------------
		// todo -----------------------------------------------------------------
		const {
			refreshKeyCreateReturnOrderDispatch,
			showCreateReturnOrderDispatch,
			showTheDispatchToSelectItemToReturn,
		} = useReturnOrderDispatchState()
		// todo ------------------------------------------------------------------
		const {
			refreshKeyCreateCreditInvoice,
			showCreateCreditInvoice,
			showTheInvoiceToSelectItemToCredit,
		} = useCreditInvoiceState()
		// todo ------------------------------------------------------------------
		const setInit = () => {
			showSearchActiveCustomers.value = true
			showDocumentHeadersGroup.value = false
			showAddGoodsTransactions.value = false
			showCreateReturnOrderDispatch.value = false
			showCreateCreditInvoice.value = false
			showTheDispatchToSelectItemToReturn.value = false
			showTheInvoiceToSelectItemToCredit.value = false
			// showCheckRiskModule.value = false
			console.log('CreateDocument is initialized')
		}
		onMounted(() => {
			console.log('CreateDocument is mounted')
			setInit()
		})
		return {
			showSearchActiveCustomers,
			showGetDocumentHeadersDataComponent,
			refreshKeyGetDocumentHeadersDataComponent,
			showDocumentHeadersGroup,
			refreshKeyDocumentHeadersGroup,
			showAddGoodsTransactions,
			refreshKeyAddGoodsTransactions,
			navbarType,
			refreshKeyCreateReturnOrderDispatch,
			showCreateReturnOrderDispatch,
			showTheDispatchToSelectItemToReturn,
			// --------------------------------------------
			refreshKeyCreateCreditInvoice,
			showCreateCreditInvoice,
			showTheInvoiceToSelectItemToCredit,
		}
	},
}
</script>

<style lang="scss" scoped></style>
